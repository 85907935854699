import checkout from "./formStops";

const {
  formField: { slot, description, ctaLabel, ctaUrl, type, venues, tickets, media, image, video },
} = checkout;

const initialValuesStops = {
  [slot.name]: "morning",
  [description.name]: "",
  [ctaLabel.name]: "",
  [ctaUrl.name]: "",
  [type.name]: "",
  [venues.name]: null,
  [tickets.name]: [],
  [media.name]: "none",
  [video.name]: "",
  [image.name]: "",
};

export default initialValuesStops;
