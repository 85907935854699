/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

// Libraries
import { useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// import { EditorState, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import TranslateIcon from "@mui/icons-material/Translate";
import AssignmentIcon from "@mui/icons-material/Assignment";
// import { IconButton } from "@mui/material";
// import Button from "@mui/material/Button";

// import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
// import MDButton from "components/MDButton";
import DynamicHeader from "components/DynamicHeader";
import DynamicTranslate from "components/DynamicTranslate";
// import MDTypography from "components/MDTypography";
import ViewEditActions from "components/DetailList/ViewEditActions";
import { useHandleError } from "components/helper";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions
import {
  getOneAttraction,
  clearErrors,
  updateAttraction,
  updateAttractionTranslations,
  setLoading,
} from "store/actions/attractionActions";
import setAlert from "store/actions/alertActions";

// Schemas
import validations from "./schemas/validations";
import form from "../new-attraction/schemas/form";
import initialValues from "../new-attraction/schemas/initialValues";
import AttractionDetail from "./components/AttractionDetail";

const EditAttraction = () => {
  const { formId, formField } = form;
  const { attraction_id } = useParams();

  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const attraction = useSelector((state) => state.attractions.attraction);
  const error = useSelector((state) => state.attractions.error);
  // const added = useSelector((state) => state.attractions.added);
  const user = useSelector((state) => state.users.user);
  const location = useSelector((state) => state.locations.location);
  // state
  const [imgDetailPage, setImgDetailPage] = useState(true);
  const [initialState, setInitialState] = useState(initialValues);
  const [image, _setImage] = useState(attraction?.logo);
  const [attractionDetailPage, setAttractionDetailPage] = useState(true);
  const [additionalDetailPage, setAdditionalDetailPage] = useState(true);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [selectedTravelDesk, setSelectedTravelDesk] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [translateItems, setTranslateItems] = useState([]);

  const detailTabs = [
    { id: 1, title: "DETAILS", iconComponent: AssignmentIcon },
    { id: 2, title: "TRANSLATE", iconComponent: TranslateIcon },
  ];

  // useEffect
  useEffect(() => {
    if (attraction_id) {
      dispatch(getOneAttraction(attraction_id));
    }
  }, [attraction_id]);

  useHandleError(error, dispatch, setAlert, clearErrors); // Use the custom hook

  useEffect(() => {
    if (attraction?.logo) {
      _setImage(attraction?.logo);
    }
  }, [attraction?.logo]);

  useEffect(() => {
    const arTranslation = attraction?.translations?.ar;

    if (attraction) {
      const arr = [
        {
          id: 1,
          name: "title",
          title: "Title",
          value: arTranslation?.title || "",
          default: "",
          label: "Title",
        },
        {
          id: 2,
          name: "callToAction",
          title: "Call to action Label",
          value: arTranslation?.callToAction?.label || "",
          default: "",
          label: "Call to action Label",
        },
        {
          id: 3,
          name: "description",
          title: "Message",
          value: arTranslation?.description || "",
          default: "",
          label: "Message",
        },
      ];
      setTranslateItems(arr);
    }
  }, [attraction]);

  // We cant use the attraction as is because of address picker and map picker
  useEffect(() => {
    if (attraction) {
      const manipulated = {};
      manipulated.title = attraction?.title ? attraction?.title : "";
      manipulated.subHeader = attraction?.subHeader ? attraction?.subHeader : "";
      manipulated.description = attraction?.description ? attraction?.description : "";
      manipulated.travelDesk = attraction?.travelDesk ? attraction?.travelDesk?._id : "";
      manipulated.retail_price = attraction?.retail_price ? attraction?.retail_price : {};
      manipulated.tag = attraction?.tag ? attraction?.tag : {};
      manipulated.destination = attraction?.destinations
        ? {
            value: attraction?.destinations[0]._id || attraction?.destinations[0].value,
            label:
              attraction?.destinations[0]?.label || attraction?.destinations[0]?.destinationName,
            type: attraction?.destinations[0]?.type || attraction?.destinations[0]?.destinationType,
          }
        : {};
      manipulated.destinations = attraction?.destinations ? attraction?.destinations : "";
      manipulated.subcategories = attraction?.subcategories ? attraction?.subcategories : [];
      manipulated.category = attraction?.category ? attraction?.category?._id : "";
      manipulated.type = attraction?.type ? attraction?.type : "";
      manipulated.callToAction = attraction?.callToAction
        ? attraction?.callToAction
        : {
            label: "",
            url: "",
            urlType: true,
          };
      manipulated.newLabel = attraction?.newLabel ? attraction?.newLabel : false;
      manipulated.sponsored = attraction?.sponsored ? attraction?.sponsored : false;
      manipulated.currated = attraction?.currated ? attraction?.currated : false;
      manipulated.special = attraction?.special ? attraction?.special : false;
      manipulated.experienceX = attraction?.experienceX ? attraction?.experienceX : false;
      manipulated.exclusive = attraction?.exclusive ? attraction?.exclusive : false;
      manipulated.insider = attraction?.insider ? attraction?.insider : false;
      manipulated.logo = attraction?.logo ? attraction?.logo : "";
      manipulated._id = attraction?._id;

      setSelectedDestination({
        _id: 1,
        label: attraction?.destinations[0]?.label || attraction?.destinations[0]?.destinationName,
      });
      setSelectedTag(attraction?.tag);
      setInitialState(manipulated);
    }
  }, [attraction]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });

  const submitForm = async (values) => {
    // const updatedValues = { ...values };
    // console.log("values submitupdate", values);
    const {
      title = "",
      subHeader = "",
      description = "",
      newLabel = false,
      sponsored = false,
      currated = false,
      special = false,
      experienceX = false,
      exclusive = false,
      insider = false,
      category = "",
      subcategories = [],
      destination = "",
      travelDesk = "",
      tag = {},
      callToAction = {
        label: "",
        url: "",
        urlType: true,
      },
      type = "url",
      retail_price = {},
    } = values;

    const baseValues = { _id: values?._id, destination: destination?.value || destination };
    let additionalValues = {};
    if (!attractionDetailPage) {
      additionalValues = { title, subHeader, travelDesk };
    } else if (!additionalDetailPage) {
      additionalValues = {
        retail_price,
        price: retail_price?.value,
        newLabel,
        sponsored,
        currated,
        special,
        experienceX,
        exclusive,
        subcategories,
        category,
        insider,
        callToAction,
        type,
        description,
        tag,
      };
      if (additionalValues?.callToAction) {
        delete additionalValues?.callToAction?.urlType;
      }

      if (additionalValues && additionalValues?.tag) {
        additionalValues.tag = additionalValues?.tag?.value;
      }
    }
    const updatedValues = { ...baseValues, ...additionalValues };
    await sleep(1000);
    dispatch(setLoading());
    // eslint-disable-next-line no-alert
    dispatch(updateAttraction(updatedValues, values?.logo));
    setAttractionDetailPage(true);
    setAdditionalDetailPage(true);
  };

  const handleSubmitTranslate = (values) => {
    const { title, callToAction, description } = values;

    // Convert callToAction to an object if it is a string
    const convertedCallToAction =
      typeof callToAction === "string" ? { label: callToAction } : callToAction;

    // Pass the values object with the modified callToAction to updateAttractionTranslations
    dispatch(
      updateAttractionTranslations(
        {
          title,
          callToAction: convertedCallToAction,
          description,
        },
        attraction?._id
      )
    );
  };

  const handleDetailData = () => {
    switch (tabValue) {
      case 1:
        return (
          <DynamicTranslate
            items={translateItems}
            handleSubmit={handleSubmitTranslate}
            id={attraction?._id}
          />
        );

      default:
        return null;
    }
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleDestinationChange = (value) => {
    if (!value || !value.label) {
      setSelectedDestination({});
    } else {
      setSelectedDestination(value);
    }
  };

  const handleTravelDeskChange = (e, value) => {
    if (value && value?._id) {
      setSelectedTravelDesk(value);
    } else {
      setSelectedTravelDesk({});
    }
  };

  const handleTagChange = (value) => {
    if (value && value?.value) {
      setSelectedTag(value);
    } else {
      setSelectedTag({});
    }
  };

  function getStepContent(type, formData) {
    let detailPage;
    let setDetailPage;

    switch (type) {
      case "attractionInfo":
        detailPage = attractionDetailPage;
        setDetailPage = setAttractionDetailPage;
        break;
      default:
        detailPage = additionalDetailPage;
        setDetailPage = setAdditionalDetailPage;
    }

    const handleEdit = () => {
      formData.resetForm();
      if (type === "attractionInfo") {
        setAttractionDetailPage(false);
        setAdditionalDetailPage(true);
      }
      if (type === "additionalInfo") {
        setAttractionDetailPage(true);
        setAdditionalDetailPage(false);
      }
      setImgDetailPage(true);
    };

    return (
      <AttractionDetail
        type={type}
        formData={formData}
        detailPage={detailPage}
        setDetailPage={setDetailPage}
        handleEdit={handleEdit}
        travelDeskObj={attraction?.travelDesk}
        DestinationObj={attraction?.destinations[0]}
        selectedTravelDesk={selectedTravelDesk}
        selectedDestination={selectedDestination}
        handleTravelDeskChange={handleTravelDeskChange}
        handleDestinationChange={handleDestinationChange}
        handleTagChange={handleTagChange}
        selectedTag={selectedTag}
        setSelectedTravelDesk={setSelectedTravelDesk}
      />
    );
  }

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      // dispatch(setLoading());
      dispatch(getOneAttraction(attraction._id));
    }
  };

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = `/admin/attractions?`;
      navigate(url);
    } else if (type === "partner") {
      url = `/partner/attractions?locationId=${location}`;
      navigate(url);
    }
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={!additionalDetailPage ? validations[1] : validations[0]}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                // setFormChange(f?.values);
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                  <MDBox>
                    <DynamicHeader
                      image={image}
                      imgDetailPage={imgDetailPage}
                      imageType="logo"
                      user={attraction}
                      detailTabs={detailTabs}
                      handleSetTabValue={handleSetTabValue}
                      tabValue={tabValue}
                      handleNavigation={() => handleNavigation(user?.role)}
                    >
                      {user?.role === "admin" && tabValue === 0 && (
                        <ViewEditActions
                          detailPage={imgDetailPage}
                          stateName="attractions"
                          handleEdit={() => {
                            setImgDetailPage(false);
                            setAttractionDetailPage(true);
                            setAdditionalDetailPage(true);
                          }}
                          handleClose={() => {
                            _setImage(attraction?.logo);
                            setImgDetailPage(true);
                          }}
                          handleSave={() => {
                            submitForm(values);
                            setImgDetailPage(true);
                          }}
                        />
                      )}
                    </DynamicHeader>

                    {tabValue === 0 ? (
                      <>
                        {["attractionInfo", "additionalInfo"].map((item, index) => (
                          <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                            <MDBox p={2}>
                              <Grid container spacing={3} alignItems="center">
                                <Grid item size={{ xs: 12 }}>
                                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    {getStepContent(item, {
                                      values,
                                      touched,
                                      formField,
                                      errors,
                                      setFieldValue,
                                      resetForm,
                                    })}
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </Card>
                        ))}
                      </>
                    ) : (
                      handleDetailData()
                    )}
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditAttraction;
