/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-underscore-dangle */

// Libraries
import PropTypes from "prop-types";

// Material UI components
import Grid from "@mui/material/Grid2";

// Custom Components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "components/FormField";
import LanguagesPicker from "components/AutoComplete/LanguagesPicker";
import DetailList from "components/DetailList";
import Danger from "components/Danger";
import InterestsCuratorPicker from "components/AutoComplete/InterestsCuratorPicker";
// import TicketsPicker from "components/AsyncSelect/TicketsPicker";

import DestinationsPicker from "components/AutoComplete/DestinationsPicker";
// import { useEffect, useState } from "react";
import ImagePicker from "components/AutoComplete/ImagePicker";
import { FormControlLabel, FormGroup } from "@mui/material";
import GreenSwitch from "components/AutoComplete/GreenSwitch";

const AdditionalCuratorInfo = ({
  formData,
  detailPage,
  edit = false,
  selectedDestination = {},
  handleDestinationsChange = () => {},
}) => {
  // const dispatch = useDispatch();
  const { formField, values, errors, touched, setFieldValue } = formData;
  const { bio, description, languages, interests, destinations, top } = formField;
  const {
    bio: bioV,
    description: descriptionV,
    languages: languagesV,
    interests: interestsV,
    destinations: destinationsV,
    top: topV,
  } = values;

  const languagesList = ["Arabic", "English", "French", "Dutch", "Spanish", "Russian"];

  return (
    <MDBox>
      {!edit && (
        <MDBox lineHeight={0} style={{ display: "flex", justifyContent: "space-between" }}>
          <MDTypography variant="h5" fontWeight="bold" textTransform="capitalize" mb={1}>
            Curator info
          </MDTypography>
        </MDBox>
      )}
      {detailPage ? (
        <DetailList
          data={{
            introText: values?.bio,
            description: values?.description,
            top: values?.top,
            interests: values?.interests,
            destinations: values?.destinations,
            languages: values?.languages,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            {!edit && (
              <Grid item size={{ xs: 12 }}>
                <ImagePicker edit imageType="photo" />
              </Grid>
            )}

            <Grid item size={{ xs: 12 }}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={topV}
                      name={top?.name}
                      onChange={(event) => setFieldValue("top", event.target.checked)}
                    />
                  }
                  label="Top"
                />
              </FormGroup>
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                // type={bio.type}
                label={bio.label}
                name={bio.name}
                value={bioV}
                multiline
                outlined
                rows={5}
                placeholder={bio.placeholder}
                error={errors.bio && touched.bio}
                success={bioV.length > 0 && !errors.bio}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <FormField
                type={description.type}
                label={description.label}
                name={description.name}
                value={descriptionV}
                placeholder={description.placeholder}
                error={errors.description && touched.description}
                success={descriptionV.length > 0 && !errors.description}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <LanguagesPicker
                languagesList={languagesList}
                languages={languages}
                languagesV={languagesV}
                setFieldValue={setFieldValue}
                error={errors.languages && touched.languages}
                success={languagesV && !errors.languages}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <InterestsCuratorPicker
                // isObj
                interests={interests}
                interestsV={interestsV}
                setFieldValue={setFieldValue}
                error={errors.interests && touched.interests}
                success={interestsV && !errors.interests}
              />
            </Grid>

            <Grid item size={{ xs: 12 }}>
              <DestinationsPicker
                setFieldValue={setFieldValue}
                handleChange={handleDestinationsChange}
                destinations={destinations}
                destinationsV={destinationsV || selectedDestination}
              />
              <Danger validation={errors?.destinations} />
            </Grid>

            {/* <Grid item size={{ xs: 12 }}>
              <MDTypography variant="button" fontWeight="regular" color="text">
                Recommended Experiences
              </MDTypography>
              <TicketsPicker
                isCuratorPage
                tickets={{ name: "ticket", Label: "Ticket" }}
                ticketsV={
                  selectedTickets?.length > 0 && selectedTickets[0]?._id?.length > 0
                    ? selectedTickets?.map((item) => ({
                        label: item?.title,
                        value: item?._id,
                      }))
                    : selectedTickets
                }
                setFieldValue={setFieldValue}
                handleChange={handleTicketChange}
              />
            </Grid> */}
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for AdditionalCuratorInfo
AdditionalCuratorInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  detailPage: PropTypes.bool.isRequired,
  edit: PropTypes.bool,
  selectedDestination: PropTypes.instanceOf(Object),
  handleDestinationsChange: PropTypes.func,
};

export default AdditionalCuratorInfo;
