import PropTypes from "prop-types";

export const defaultProps = {
  token: "https://lokalee.app",
  user: { name: "Lokalee", email: "info@lokalee.app", phone: "" },

  data: {
    ref: {
      title: " Lake boat ride with Dubai Fountain show Standard price",
      reviews: { combinedAverageRating: 3 },
      description:
        "Short description lorem ipsum dolor sit amet,consectetur adipiscing elit, Short description lorem ipsum dolor sit amet,consectetur adipiscing elit.",
      inclusions: [
        {
          otherDescription: "",
          description: "",
          typeDescription: "",
          included:
            " Short description lorem ipsum dolor sit amet,consectetur adipiscing elit, Short description lorem ipsum dolor sit amet,consectetur adipiscing elit.",
        },
      ],
      additionalInfo: [
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi enim vel euismod et id eu accumsan sed ornare. Tellus erat placerat non ac, quam egestas facilisi libero.Amet vel justo egestas sagittis.",
      ],
      slug: "paragliding-activity-in-lebanon",
      coverImage: "https://email-assets.lokalee.app/email/emailImages/fullWidth-cover.png",
      whatToExpect:
        " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi enim vel euismod et id eu accumsan sed ornare. Tellus erat placerat non ac, quam egestas facilisi libero.Amet vel justo egestas sagittis.",
      logistics: {
        start: [
          {
            pin: {
              id: {
                geoLocation: {
                  address:
                    "Restaurant Village Four Seasons Resort - Jumeirah Beach Rd - Jumeirah 2 - Dubai - United Arab Emirates",
                },
              },
            },
          },
        ],
      },
    },
    startTime: "08:00",
    travelDate: "2 Apr 2024",
    totalPax: "2",
    itemTotalPrice: { price: { recommendedRetailPrice: "280" } },
    discount: 25,
  },
};

export const propTypes = {
  token: PropTypes.string,
  data: PropTypes.shape({
    ref: PropTypes.shape({
      title: PropTypes.string,
      rating: PropTypes.number,
      reviews: PropTypes.shape({
        combinedAverageRating: PropTypes.number,
      }),

      description: PropTypes.string,
      inclusion: PropTypes.instanceOf(Array),
    }),
    discount: PropTypes.number,
    whatToExpect: PropTypes.string,
    logistics: PropTypes.shape({
      start: PropTypes.arrayOf(
        PropTypes.shape({
          pin: PropTypes.shape({
            id: PropTypes.shape({ geoLocation: PropTypes.shape({ address: PropTypes.string }) }),
          }),
        })
      ),
    }),
    startTime: PropTypes.string,
    travelDate: PropTypes.string,
    totalPax: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    itemTotalPrice: PropTypes.shape({
      price: PropTypes.shape({
        recommendedRetailPrice: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      }),
    }),
  }),
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
  }),
};
