const form = {
  formId: "new-location-form",
  formField: {
    images: {
      name: "images",
      label: "Select the desktop image *",
      type: "text",
      errorMsg: "Desktop Image is required!",
    },
    mobileImages: {
      name: "mobileImages",
      label: "Select the mobile image *",
      type: "text",
      errorMsg: "Mobile Image is required!",
    },
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Banner Title is required!",
      invalidMsg: "Banner title should have at least 1 letter",
    },
    type: {
      name: "type",
      label: "Select where to show the slider:",
      type: "text",
    },
    subTitle: {
      name: "subTitle",
      label: "Subtitle",
      type: "text",
      invalidMsg: "Banner subtitle should have at least 1 letter",
    },
    ctaLabel: {
      name: "ctaLabel",
      label: "Banner Label",
      type: "text",
    },
    ctaUrl: {
      name: "ctaUrl",
      label: "Banner URL",
      type: "text",
      invalidMsg: "Please enter a valid contact url! (http://www.url.com)!",
    },
    startDate: {
      name: "startDate",
      label: "Start date",
      type: "text",
      errorMsg: "Start Date is required!",
    },
    endDate: {
      name: "endDate",
      label: "End date",
      type: "text",
      errorMsg: "End Date is required!",
    },
    slideDuration: {
      name: "slideDuration",
      label: "Duration",
      type: "number",
    },
  },
};

export default form;
