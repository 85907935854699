const form = {
  formId: "new-partner-form",
  formField: {
    // partner page
    title: {
      name: "title",
      label: "Title *",
      type: "text",
      errorMsg: "Title is required!",
      invalidMsg: "Title should have at least 1 letter!",
    },
    introText: {
      name: "introText",
      label: "Intro Text *",
      type: "text",
      errorMsg: "Intro Text is required!",
      invalidMsg: "Intro Text should have at least 1 letter!",
    },
    curator: {
      name: "curator",
      label: "Curator *",
      type: "object",
      errorMsg: "Curator is required!",
    },
    destination: {
      name: "destination",
      label: "Destination *",
      type: "object",
      errorMsg: "Destination is required!",
    },
    travelStyle: {
      name: "travelStyle",
      label: "Travel Style *",
      type: "text",
      errorMsg: "Travel Style is required!",
      invalidMsg: "Travel Style should have at least 1 letter!",
    },
    days: {
      name: "days",
      label: "Number of days *",
      type: "number",
    },
    images: {
      name: "images",
      label: "Cover photo *",
      type: "text",
      errorMsg: "Cover photo is required!",
    },
  },
};

export default form;
