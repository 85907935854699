/* eslint-disable no-underscore-dangle */

// prop-type is a library for typechecking of props
import { Autocomplete } from "@mui/material";
import FormField from "components/FormField";
import PropTypes from "prop-types";
import specialDietsList from "./specialDietsList";

const SpecialDiets = ({
  specialDiets,
  specialDietsV,
  setFieldValue,
  handleDietsChange = () => {},
  ...rest
}) => (
  <Autocomplete
    id="specialDiets"
    multiple
    filterSelectedOptions
    options={specialDietsList}
    onChange={(event, value) => {
      if (value) {
        handleDietsChange(value);
        setFieldValue("specialDiets", value);
      } else {
        setFieldValue("specialDiets", []);
      }
    }}
    value={specialDietsV || []}
    getOptionLabel={(option) => (typeof option === "string" ? option : option.label)}
    isOptionEqualToValue={(option, value) => option.value === value.value}
    renderInput={(params) => (
      <FormField
        {...params}
        {...rest}
        label={specialDiets?.label}
        name={specialDiets?.name}
        onChange={() => null}
      />
    )}
  />
);

// typechecking props for SpecialDiets
SpecialDiets.propTypes = {
  specialDiets: PropTypes.instanceOf(Object).isRequired,
  specialDietsV: PropTypes.instanceOf(Array).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleDietsChange: PropTypes.func,
};

export default SpecialDiets;
