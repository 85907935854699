/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import MDBox from "components/MDBox";
import { Avatar, Divider } from "@mui/material";
import MDAvatar from "components/MDAvatar";
import DetailList from "components/DetailList";
import { useEffect, useState } from "react";
import { areAllValuesEmpty } from "components/helper";
import { useSelector } from "react-redux";
import { exportDayName } from "utils/function/utilFunctions";

const BigAvatar = styled(Avatar)`
  width: 74px;
  height: 74px;
`;

const Review = ({ formData, editPage = false, selectedPrimaryDestination = {} }) => {
  const { values } = formData;
  const [recommendationInfoData, setRecommendationInfoData] = useState({});
  const [addressInfoData, setAddressInfoData] = useState({});
  const [dateAndTimeData, setDateAndTimeData] = useState({});
  const [addInfoData, setAddInfoData] = useState({});
  const [logoUrl, setLogoUrl] = useState(null);
  const [fields, setFields] = useState([]);

  const locations = useSelector((state) => state.locations.locations);
  const locationPartnerId = useSelector((state) => state.locations.location);
  const locationPartner = locations.find((loc) => loc._id === locationPartnerId);

  useEffect(() => {
    let url;
    if (values?.logo) {
      url = URL.createObjectURL(values.logo);
      setLogoUrl(url);
    } else {
      setLogoUrl(null);
    }
    return () => {
      if (url) {
        URL.revokeObjectURL(url);
      }
    };
  }, [values?.logo]);

  const handleDetailsList = (data) => {
    const list = {};
    data.forEach((openHour) => {
      let status = "";
      const {
        weekDay = 0,
        closed = false,
        open_all_day = false,
        openHours: openHoursRow = [],
      } = openHour;

      if (open_all_day) {
        status = "Open All Day";
      } else if (closed) {
        status = "Closed";
      } else {
        status = openHoursRow
          .map((item) => {
            const { open, close } = item;
            return `${open} - ${close}`;
          })
          .join(" & ");
      }

      list[exportDayName(weekDay)] = status;
    });

    return list;
  };

  useEffect(() => {
    if (values) {
      setRecommendationInfoData({
        title: values?.title,
        subHeader: values?.subHeader,
        description: values?.description,
        tags: values?.tags,
        cuisines: values?.cuisines,
        features: values?.features,
        bookable: values?.bookable,
      });
      setAddressInfoData({
        primaryDestination: selectedPrimaryDestination?.label,
        destinations:
          values?.destinations && values?.destinations.length > 0
            ? values?.destinations.map((option) => option.label).join(", ")
            : "",
        location: values?.location || locationPartner?.name || "",
        address: values?.address,
        country: values?.country,
        city: values?.city,
        lat: values?.lat,
        lng: values?.lng,
      });
      setAddInfoData({
        email: values?.email,
        phone: values?.phone,
        webAddresses: values?.webAddresses,
        callToAction: values?.callToAction,
        callToAction2: values?.callToAction2,
        price: values?.price,
        sponsored: values?.sponsored,
        currated: values?.currated,
        highlight: values?.highlight,
        special: values?.special,
        experienceX: values?.experienceX,
        exclusive: values?.exclusive,
        insider: values?.insider,
        exclusiveLocation: values?.exclusiveLocation,
        exclusivePartner: values?.exclusivePartner,
        top: values?.top,
        traveltype: values?.traveltype,
        images: values?.images,
        badges: values?.badges?.label,
        specialDiets: values?.specialDiets,
        meals: values?.meals,
        minPrice: values?.min,
        maxPrice: values?.max,
      });
      setDateAndTimeData(handleDetailsList(values?.openHours || []));
    }
  }, [values, selectedPrimaryDestination, locationPartner]);

  useEffect(() => {
    setFields([
      { id: 0, title: "Recommendation Info", data: recommendationInfoData },
      { id: 1, title: "Address", data: addressInfoData },
      { id: 4, title: "Additional Info", data: addInfoData },
      { id: 5, title: "Date and Time Info", data: dateAndTimeData },
    ]);
  }, [recommendationInfoData, addressInfoData, addInfoData, dateAndTimeData]);

  return (
    <MDBox>
      <MDBox mt={1.625}>
        <Grid container spacing={3}>
          {!editPage && (
            <Grid item size={{ xs: 12, sm: 6 }}>
              {logoUrl ? (
                <MDAvatar
                  src={logoUrl}
                  alt="profile-image"
                  size="xl"
                  sx={{ "& img": { height: "100%" } }}
                />
              ) : (
                <BigAvatar
                  alt="Default Avatar"
                  src="/path/to/placeholder.png" // Provide a valid path to your placeholder image
                  imgProps={{
                    style: {
                      maxHeight: "100%",
                      maxWidth: "100%",
                      objectFit: "cover",
                    },
                  }}
                />
              )}
            </Grid>
          )}
          {fields.map((field, i) => (
            <Grid
              item
              key={field.id}
              size={{ xs: 12 }}
              sx={{
                display: areAllValuesEmpty(field.data) ? "none" : "flex",
                flexDirection: "column",
              }}
            >
              <DetailList data={field.data} title={field.title} />
              {i + 1 !== fields.length && <Divider sx={{ height: "1px" }} />}
            </Grid>
          ))}
        </Grid>
      </MDBox>
    </MDBox>
  );
};

Review.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  selectedPrimaryDestination: PropTypes.instanceOf(Object),
};

export default Review;
