import * as Yup from "yup";
import axios from "axios";
import checkout from "./form";

const URL = process.env.REACT_APP_API;

const {
  formField: {
    name,
    email,
    partner,
    address,
    logo,
    disclaimerText,
    primaryDestination,
    destinations,
  },
} = checkout;

const locationCheckValidation = Yup.string()
  .required(name.errorMsg)
  .matches(/([A-Za-z])/, name.invalidMsg)
  .test(
    "checkNameOnServer",
    "Name check failed; please try again",
    async function checkNameOnServer(value) {
      if (!value) return true;
      try {
        await axios.post(`${URL}/locations/checkName`, { name: value });
        return true;
      } catch (err) {
        const msg = err?.response?.data?.message || "Name is invalid";
        return this.createError({ message: msg });
      }
    }
  );
const partnerCheckValidation = Yup.string()
  .required(partner.errorMsg)
  .test(
    "checkPartnerOnServer",
    "Partner check failed; please try again",
    async function checkPartnerOnServer(value) {
      if (!value) return true;
      try {
        await axios.post(`${URL}/locations/liscenceCheck`, { partner: value });
        return true;
      } catch (err) {
        const msg = err?.response?.data?.message || "Partner is invalid";
        return this.createError({ message: msg });
      }
    }
  );

const validations = [
  Yup.object().shape({
    [logo.name]: Yup.string().required(logo.errorMsg).nullable(),
    [email.name]: Yup.array().of(
      Yup.string()
        .email(email.invalidMsg)
        .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, email.invalidMsg)
    ),
    [name.name]: locationCheckValidation,
    [partner.name]: partnerCheckValidation,

    [disclaimerText.name]: Yup.object().when("disclaimer", {
      is: true,
      then: () =>
        Yup.object()
          .test("editorState", disclaimerText.errorMsg, (value) => {
            if (value && value?.blocks && value?.blocks[0]?.text?.length) {
              return true;
            }
            return false;
          })
          .required("This field is required."),
    }),
  }),
  Yup.object().shape({
    [address.name]: Yup.string()
      .matches(/([A-Za-z\u0600-\u06FF])/, address.invalidMsg)
      .required(address.errorMsg),
    [primaryDestination.name]: Yup.string().required(primaryDestination.errorMsg),
    [destinations.name]: Yup.array()
      .min(1, destinations?.invalidMsg)
      .required(destinations?.errorMsg),
  }),
];

export default validations;
