/* eslint-disable no-underscore-dangle */
import React from "react";
import Grid from "@mui/material/Grid2";
import CuratorPicker from "components/AsyncSelect/CuratorPicker";
import Danger from "components/Danger";
import PropTypes from "prop-types";
import DestinationDropdown from "components/AutoComplete/DestinationDropdown";

const CuratorWithDestination = ({
  values,
  errors,
  touched,
  setFieldValue,
  handleCuratorChange,
  curator,
  source,
  selectedCurator,
  sourceId,
  displayErr,
  destination,
}) => (
  <>
    <Grid item size={{ xs: 12 }}>
      <CuratorPicker
        curator={curator}
        handleChange={handleCuratorChange}
        setFieldValue={setFieldValue}
        selectedCurator={selectedCurator}
        disabled={source === "users" && !!sourceId}
        withDestination
      />
      {displayErr && errors?.curator && <Danger validation={errors?.curator} />}
    </Grid>
    <Grid item size={{ xs: 12 }}>
      <DestinationDropdown
        dataList={
          selectedCurator?.destinations?.map((dest) => ({
            _id: dest._id,
            destinationName: dest.destinationName,
            slug: dest.slug,
          })) || []
        }
        destination={destination} // from formField (or define shape for label/name)
        setFieldValue={setFieldValue}
        destinationV={values?.destination} // The form value
        error={errors.destination && touched.destination}
        success={values?.destination?.length > 0 && !errors.destination}
      />
    </Grid>
  </>
);

CuratorWithDestination.propTypes = {
  values: PropTypes.shape({
    destination: PropTypes.instanceOf(Object),
    curator: PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      contact: PropTypes.string,
      phone: PropTypes.string,
      email: PropTypes.string,
      destinations: PropTypes.instanceOf(Array),
    }),
  }).isRequired,
  errors: PropTypes.instanceOf(Object).isRequired,
  curator: PropTypes.instanceOf(Object).isRequired,
  touched: PropTypes.instanceOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleCuratorChange: PropTypes.func.isRequired,
  source: PropTypes.string.isRequired,
  selectedCurator: PropTypes.instanceOf(Object).isRequired,
  sourceId: PropTypes.string.isRequired,
  displayErr: PropTypes.bool.isRequired,
  destination: PropTypes.string.isRequired,
};

export default React.memo(CuratorWithDestination);
