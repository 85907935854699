const onlineStoreForm = {
  onlineFormId: "onlineStoreForm",
  addProductsFields: {
    productName: {
      type: "text",
      name: "productName",
      label: "Product Name *",
      placeholder: "ex. Product Name",
      errorMessage: "Product Name is required!",
      invalidMessage: "Product Name should have at least 1 letter!",
    },

    productCode: {
      type: "number",
      name: "productCode",
      label: "Product Code",
      placeholder: "ex. 123456",
    },
    tripPlanner: {
      name: "tripPlanner",
      label: "Trip Planner *",
    },

    price: {
      name: "price",
      type: "number",
      label: "Retail Price *",
      placeholder: "ex. 100",
      errorMessage: "Price is required!",
      invalidMessage: "Price should be greater than 0!",
    },

    netPrice: {
      name: "netPrice",
      type: "number",
      label: "Net Price *",
      placeholder: "ex. 100",
      errorMessage: "Net Price is required!",
      invalidMessage: "Net Price should be greater than 0!",
    },

    defaultCurrencyCode: {
      name: "defaultCurrencyCode",
      label: "Currency",
      type: "text",
    },

    tag: {
      type: "select",
      name: "tag",
      label: "Tag *",
      errorMessage: "Tag is required!",
    },

    subTag: {
      type: "select",
      name: "subTag",
      label: "Sub Tag",
    },
  },

  settingFields: {
    paymentMethod: {
      type: "checkbox",
      name: "paymentMethod",
      label: "Payment Method *",
      errorMessage: "Payment Method is required!",
      options: [
        { name: "cash", label: "Cash On Delivery (COD)" },
        { name: "credit", label: "Credit Card" },
        { name: "charges", label: "Room Charges" },
      ],
    },
    orderProcessingType: {
      type: "checkbox",
      name: "orderProcessingType",
      label: "Order Processing Type *",
      errorMessage: "Order Processing Type is required!",
      options: [
        { name: "emailToCs", label: "Email to CS" },
        { name: "guestRequest", label: "Guest Request Management System" },
      ],
    },
    staffEmail: {
      type: "text",
      name: "staffEmail",
      label: "Staff Email *",
      placeholder: "ex. example@domain.com",
      errorMessage: "Staff Name is required!",
      invalidMessage: "Staff Name should have at least 1 letter!",
    },
    systemPicker: {
      type: "select",
      name: "systemPicker",
      label: "System Picker",
    },
  },
};

export default onlineStoreForm;
