/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

// Libraries
import { useCallback, useEffect, useRef, useState } from "react";
import { Formik, Form } from "formik";
import { Navigate, useNavigate, useParams, useSearchParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
// import { EditorState, convertFromRaw } from "draft-js";
import "draft-js/dist/Draft.css";

// @mui material components
import Grid from "@mui/material/Grid2";
import Card from "@mui/material/Card";
import TranslateIcon from "@mui/icons-material/Translate";
import AssignmentIcon from "@mui/icons-material/Assignment";

// Custom Components
import MDBox from "components/MDBox";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import DynamicHeader from "components/DynamicHeader";
import ViewEditActions from "components/DetailList/ViewEditActions";
import DynamicTranslate from "components/DynamicTranslate";

// Layouts / Pages
import DashboardLayout from "layouts/DashboardLayout";

// Redux Actions
import {
  // updateTicket,
  clearErrors,
  getOneTicket,
  updateTicketTranslations,
  setLoading,
  addTicketStep0,
  addTicketStep2,
  addTicketStep1,
  addTicketStep3,
  addTicketStep4,
  getTicketTranslations,
} from "store/actions/itemAction";
import setAlert from "store/actions/alertActions";

// Schemas
import { clearErrors as clearCityErrors } from "store/actions/cityActions";
import {
  resetFormToInitialState,
  transformItineraryItemsData,
  transformSeasonsArray,
} from "components/helper";
import * as Yup from "yup";
import validations from "./schemas/validations";
import form from "../new-ticket/schemas/form";
import initialValues from "../new-ticket/schemas/initialValues";
import TicketDetail from "./components/TicketDetail";

const EditTicket = () => {
  const { formId, formField } = form;
  const { ticket_id } = useParams();

  const [searchParams] = useSearchParams();
  const source = searchParams.get("source");
  const sourceId = searchParams.get("sourceId");

  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // selector
  const ticket = useSelector((state) => state.items.item);
  const error = useSelector((state) => state.items.error);
  // const added = useSelector((state) => state.items.added);
  const user = useSelector((state) => state.users.user);
  const translationTicket = useSelector((state) => state.items.translationTicket);

  // state
  const [initialState, setInitialState] = useState(initialValues);

  const [image, _setImage] = useState(ticket?.images?.[0]?.variants?.[0]?.url);
  const [imgDetailPage, setImgDetailPage] = useState(true);
  const [formRefVal, setFormRefVal] = useState(null);
  const [ticketDetailPage, setTicketDetailPage] = useState(true);
  const [mediaDetailPage, setMediaDetailPage] = useState(true);
  const [schedulesAndPricingDetailPage, setSchedulesAndPricingDetailPage] = useState(true);
  const [bookingAndTicketsDetailPage, setBookingAndTicketsDetailPage] = useState(true);
  const [selectedPickupPoint, setSelectedPickupPoint] = useState({});

  const [activeStep, setActiveStep] = useState(0);
  const [contentDetailPage, setContentDetailPage] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedTitleInc, setSelectedTitleInc] = useState({});
  const [selectedTitleExc, setSelectedTitleExc] = useState({});
  const [selectedPin, setSelectedPin] = useState({});
  const [selectedVenue, setSelectedVenue] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [translateItems, setTranslateItems] = useState([]);
  const [selectedPrimaryDestination, setSelectedPrimaryDestination] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [selectedSecondaryDestination, setSelectedSecondaryDestination] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCurator, setSelectedCurator] = useState([]);

  const detailTabs = [
    { id: 1, title: "Ticket DETAILS", iconComponent: AssignmentIcon },
    { id: 2, title: "TRANSLATE", iconComponent: TranslateIcon },
  ];
  const [selectedTimeAct, setSelectedTimeAct] = useState({ value: "12:00", label: "12:00" });

  const getDefaultActivity = (days) => {
    if (days === 0) {
      return { name: "On day of Activity", value: 0 };
    }
    if (days === 1) {
      return { name: "1 day before Activity", value: 1 };
    }
    if (days === 2) {
      return { name: "2 days before Activity", value: 2 };
    }
    return { name: "On day of Activity", value: 0 };
  };

  const [selectedActivity, setSelectedActivity] = useState(
    getDefaultActivity(ticket?.bookingConfirmationSettings?.days)
  );

  useEffect(() => {
    if (user?.role !== "admin" && user?.role !== "curator" && ticket?.partner?._id !== user._id) {
      <Navigate to="/" />;
    }

    if (ticket) {
      const arr = [
        {
          id: 1,
          name: "title",
          title: "Title",
          value: translationTicket?.title || "",
          default: "",
          style: { fontSize: "15px" },
          label: "Title",
        },

        {
          id: 2,
          name: "description",
          title: "Description",
          value: translationTicket?.description || "",
          default: "",
          style: { fontSize: "15px" },
          label: "Description",
        },
      ];
      setTranslateItems(arr);
    }
  }, [ticket, translationTicket]);

  useEffect(() => {
    if (ticket?.bookingConfirmationSettings?.days) {
      setSelectedActivity(getDefaultActivity(ticket?.bookingConfirmationSettings?.days));
    }
  }, [ticket?.bookingConfirmationSettings?.days]);

  const [selectedBookingConfirmation, setSelectedBookingConfirmation] = useState({
    name: "Instant confirmation",
    value: "INSTANT",
  });

  const emailSchemaValidation = Yup.object().shape({
    provider: Yup.object().shape({
      email: Yup.string()
        .email("Your provider email address is invalid!")
        .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, "Your email address is invalid!"),
    }),
  });

  const currentValidation = !ticketDetailPage
    ? validations[0].concat(emailSchemaValidation)
    : !contentDetailPage
    ? validations[1]
    : !mediaDetailPage
    ? validations[2]
    : !schedulesAndPricingDetailPage
    ? validations[3]
    : !bookingAndTicketsDetailPage
    ? validations[4]
    : undefined;

  // useEffect

  useEffect(() => {
    if (ticket_id) {
      dispatch(getTicketTranslations(ticket_id));
    }
  }, [detailTabs[1]?.id === 7, ticket_id]);

  useEffect(() => {
    if (ticket_id) {
      dispatch(getOneTicket(ticket_id));
    }
  }, [ticket_id]);

  useEffect(() => {
    if (formRefVal?.city) {
      dispatch(clearCityErrors());
    }
  }, [formRefVal, formRefVal?.city]);

  useEffect(() => {
    if (ticket && ticket.bookingConfirmationSettings?.bookingCutoffFixedTime?.length > 0) {
      setSelectedTimeAct({
        label: ticket.bookingConfirmationSettings.bookingCutoffFixedTime,
        value: ticket.bookingConfirmationSettings.bookingCutoffFixedTime,
      });
    }
  }, [ticket]);

  useEffect(() => {
    if (error) {
      dispatch(setAlert(error, "error"));
      dispatch(clearErrors());
      // Reset the form to its initial state
      resetFormToInitialState(formRef, initialState);
    }
  }, [error, setAlert, clearErrors]);

  useEffect(() => {
    if (ticket?.images?.length > 0) {
      _setImage(ticket?.images?.[0]?.variants?.[0]?.url);
    }
  }, [ticket?.images?.length, ticket?.images]);

  function transformArray(data) {
    return data.map((item) => ({
      type: {
        label: item.typeDescription,
        value: item.type,
      },
      description: item.type === "OTHER" ? item.typeDescription : "", // Use an empty string if description is not provided
    }));
  }

  function transformPricesArray(bookableItems) {
    const transformed = [];

    bookableItems.forEach((item) => {
      item.seasons.forEach((season) => {
        season.pricingRecords.forEach((record) => {
          record.pricingDetails.forEach((detail) => {
            transformed.push({
              minQty: detail.minTravelers,
              maxQty: detail.maxTravelers,
              retailPrice: detail.price?.original?.recommendedRetailPrice,
              netPrice: detail.price?.original?.partnerNetPrice,
            });
          });
        });
      });
    });

    return transformed;
  }

  const transformedTravelerPickupStart = (values) => {
    const result = values?.map((item) => ({
      ...item?.pin?.id, // Directly spread the properties of item.id into the result
    }));

    return result;
  };

  const transformedTravelerPickupEnd = (values) => {
    const result = values?.map((item) => ({
      ...item?.pin?.id, // Directly spread the properties of item.id into the result
    }));

    return result;
  };

  function getPhysicalDifficultyLevel(additionalInfo) {
    const physicalTypes = ["PHYSICAL_EASY", "PHYSICAL_MEDIUM", "PHYSICAL_HARD"];
    // Find the first item in additionalInfo that matches one of the physicalTypes
    const foundItem = additionalInfo.find((item) => physicalTypes.includes(item.type));
    return foundItem ? foundItem.type : "PHYSICAL_EASY";
  }

  function getHealthRestrictions(additionalInfo) {
    const healthTypes = ["NO_BACK_PROBLEMS", "NO_PREGNANT", "NO_HEART_PROBLEMS"];
    // Filter the additionalInfo array to get all items that match the healthTypes
    const matchedItems = additionalInfo
      .filter((item) => healthTypes.includes(item.type))
      .map((item) => item.type);

    // Return the array of matched types
    return matchedItems;
  }

  function getPhysicalRestrictions(additionalInfo) {
    const items = [
      "WHEELCHAIR_ACCESSIBLE",
      "TRANSPORTATION_WHEELCHAIR_ACCESSIBLE",
      "SURFACES_WHEELCHAIR_ACCESSIBLE",
      "STROLLER_ACCESSIBLE",
      "PETS_WELCOME",
      "PUBLIC_TRANSPORTATION_NEARBY",
      "INFANTS_MUST_SIT_ON_LAPS",
      "INFANT_SEATS_AVAILABLE",
    ];
    // Filter the additionalInfo array to get all items that match the healthTypes
    const matchedItems = additionalInfo
      .filter((item) => items.includes(item.type))
      .map((item) => item.type);

    // Return the array of matched types
    return matchedItems;
  }

  function combineInfoAsStrings(selectedAdditionalInfo, restrictions, physicalDifficultyLevelV) {
    let combinedInfo = [];

    // Combine all the inputs into the combinedInfo array
    combinedInfo = combinedInfo.concat(selectedAdditionalInfo, restrictions);

    // Add physicalDifficultyLevelV to the combinedInfo array if it exists
    if (physicalDifficultyLevelV) {
      combinedInfo.push(physicalDifficultyLevelV);
    }

    return combinedInfo;
  }

  // We cant use the ticket as is because of address picker and map picker
  useEffect(() => {
    const fetchData = async () => {
      if (ticket) {
        const manipulated = {};
        manipulated.title = ticket?.title ? ticket?.title : "";

        // manipulated.description = ticket?.description ? ticket?.description : "";

        // manipulated.logo = ticket?.logo ? ticket?.logo : "";
        manipulated.location = ticket?.location ? ticket?.location : "";

        manipulated.destinations = ticket?.destinations
          ? ticket?.destinations?.map((destination) => ({
              label: destination?.destinationName,
              value: destination?._id,
              type: destination.destinationType,
            }))
          : [];
        manipulated.primaryDestination = ticket?.primaryDestination
          ? ticket?.primaryDestination
          : {};
        manipulated.itinerary = ticket?.itinerary ? ticket?.itinerary : {};

        manipulated.itineraryItems = ticket?.itinerary?.itineraryItems
          ? transformItineraryItemsData(ticket?.itinerary?.itineraryItems)
          : [];
        manipulated.bookingConfirmationSettings = ticket?.bookingConfirmationSettings
          ? ticket?.bookingConfirmationSettings
          : {};
        manipulated.ticketInfo = ticket?.ticketInfo ? ticket?.ticketInfo : {};
        manipulated.availabilities = ticket?.availabilities ? ticket?.availabilities : {};
        manipulated.video = ticket?.video ? ticket?.video : "";
        manipulated.tags = ticket?.tags?.length > 0 ? ticket?.tags : [];
        manipulated.themes = ticket?.tags?.length > 0 ? ticket?.tags?.slice(1) : [];
        manipulated.tag = ticket?.tags ? ticket?.tags[0] : {};
        manipulated.badges = ticket?.badges?.length > 0 ? ticket?.badges[0] : {};
        manipulated.languageGuides = ticket?.languageGuides ? ticket?.languageGuides : [];
        manipulated.productOptions = ticket?.productOptions ? ticket?.productOptions : [];
        manipulated.additionalPickupInfo = ticket?.logistics?.travelerPickup?.additionalInfo
          ? ticket?.logistics?.travelerPickup?.additionalInfo
          : "";
        manipulated.allowCustomTravelerPickup = ticket?.logistics?.travelerPickup
          ?.allowCustomTravelerPickup
          ? ticket?.logistics?.travelerPickup?.allowCustomTravelerPickup
          : false;
        manipulated.scheduleOrOption = ticket?.productOptions?.length > 0 ? "option" : "schedule";
        manipulated.curatorOrProv = ticket?.provider?.name?.length > 0 ? "provider" : "curator";
        manipulated.durationRadio2 = ticket?.itinerary?.duration?.from
          ? "flexibleDuration"
          : "specificDuration";
        manipulated.additionalInfo =
          ticket?.additionalInfo?.length > 0 ? ticket?.additionalInfo : [];
        manipulated.physicalDifficultyLevel = ticket?.additionalInfo
          ? getPhysicalDifficultyLevel(ticket.additionalInfo)
          : "PHYSICAL_EASY";
        manipulated.healthRestrictions = ticket?.additionalInfo
          ? getHealthRestrictions(ticket.additionalInfo)
          : [];
        manipulated.physicalRestrictions = ticket?.additionalInfo
          ? getPhysicalRestrictions(ticket.additionalInfo)
          : [
              "WHEELCHAIR_ACCESSIBLE",
              "STROLLER_ACCESSIBLE",
              "PETS_WELCOME",
              "INFANTS_MUST_SIT_ON_LAPS",
            ];
        manipulated.inclusions = ticket?.inclusions ? transformArray(ticket?.inclusions) : [];
        manipulated.exclusions = ticket?.exclusions ? transformArray(ticket?.exclusions) : [];
        manipulated.pricesObject = ticket?.availabilities?.bookableItems
          ? transformPricesArray(ticket.availabilities.bookableItems)
          : [];
        manipulated.logistics = ticket?.logistics ? ticket?.logistics : {};
        manipulated.pricingInfo = ticket?.pricingInfo ? ticket?.pricingInfo : {};
        manipulated.ageBands = ticket?.pricingInfo?.ageBands
          ? ticket.pricingInfo.ageBands.map((ageBandInfo) => ageBandInfo.ageBand)
          : [];
        manipulated.ageBandsObject = ticket?.pricingInfo?.ageBands
          ? ticket.pricingInfo.ageBands.map((ageBandInfo) => ageBandInfo)
          : [];
        manipulated.provider = ticket?.provider ? ticket?.provider : {};
        manipulated.cancellationPolicy = ticket?.cancellationPolicy
          ? ticket?.cancellationPolicy
          : {};
        manipulated.maxTravelersPerBooking = ticket?.maxTravelersPerBooking
          ? ticket?.maxTravelersPerBooking
          : "";
        manipulated.minTravelersPerBooking = ticket?.minTravelersPerBooking
          ? ticket?.minTravelersPerBooking
          : "";
        const languageNames = new Intl.DisplayNames(["en"], { type: "language" });

        const transformedLanguages = ticket?.languageGuides?.map((language) => ({
          name: languageNames.of(language.language),
          code: language.language,
          type: language.type,
        }));
        manipulated.languages = ticket?.languageGuides ? transformedLanguages : [];
        manipulated.day2 = ticket?.itinerary?.duration?.day && ticket?.itinerary?.duration?.day;
        manipulated.hour2 = ticket?.itinerary?.duration?.hour && ticket?.itinerary?.duration?.hour;
        manipulated.min2 =
          ticket?.itinerary?.duration?.minute && ticket?.itinerary?.duration?.minute;
        manipulated.curator = ticket?.curator ? ticket?.curator : {};
        manipulated.bookingRequirements = ticket?.bookingRequirements
          ? ticket?.bookingRequirements
          : {};
        manipulated.skipTheLine = ticket?.skipTheLine ? ticket?.skipTheLine : false;

        manipulated.images =
          ticket?.images?.length > 0 ? ticket.images.map((img) => img?.variants?.[0]?.url) : [];

        manipulated.tags = ticket?.tags
          ? ticket?.tags?.map((tag) => ({
              label: tag?.title || tag?.label,
              value: tag?._id || tag?.value,
            }))
          : [];

        manipulated.pinsStart = ticket?.logistics?.start
          ? transformedTravelerPickupStart(ticket?.logistics?.start)
          : [];
        manipulated.pinsEnd = ticket?.logistics?.end
          ? transformedTravelerPickupEnd(ticket?.logistics?.end)
          : [];
        manipulated.pickupOptionType = ticket?.logistics?.travelerPickup?.pickupOptionType
          ? ticket?.logistics?.travelerPickup?.pickupOptionType
          : "";
        manipulated.highlight = ticket?.highlight ? ticket?.highlight : false;
        manipulated.containStops = ticket?.itinerary?.itineraryItems?.length > 0;
        manipulated.top = ticket?.top ? ticket?.top : false;
        manipulated.defaultCurrencyCode = ticket?.availabilities?.currency
          ? {
              code: ticket?.availabilities?.currency,
              name: ticket?.availabilities?.currency,
            }
          : null;
        manipulated.overview = ticket?.description ? ticket?.description : "";
        manipulated.minutesBeforeDepartureTimeForPickup = ticket?.logistics?.travelerPickup
          ?.minutesBeforeDepartureTimeForPickup
          ? ticket?.logistics?.travelerPickup?.minutesBeforeDepartureTimeForPickup
          : 0;
        manipulated.availabilitiesAdded = ticket?.availabilities?.bookableItems
          ? transformSeasonsArray(ticket?.availabilities?.bookableItems, ticket?.productOptions)
          : [];

        manipulated._id = ticket?._id;
        _setImage(ticket?.images?.[0]?.variants?.[0]?.url);

        setInitialState(manipulated);
      }
      setSelectedTags(ticket?.tags);
      setSelectedLocation(ticket?.location);
    };
    fetchData();
  }, [ticket]);

  const urlToBinary = async (url) => {
    const response = await fetch(`${url}?additional-param`);
    if (!response.ok) {
      throw new Error(
        `Failed to fetch image from ${url}. Status: ${response.status} ${response.statusText}`
      );
    }
    const arrayBuffer = await response.arrayBuffer();
    return new Uint8Array(arrayBuffer);
  };

  const blobToBinary = async (blob) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(new Uint8Array(reader.result));
      reader.onerror = reject;
      reader.readAsArrayBuffer(blob);
    });

  const handleTimeActChange = (val) => {
    if (val) {
      setSelectedTimeAct(val);
    } else {
      setSelectedTimeAct({});
    }
  };

  const handleActivityChange = (val) => {
    if (val) {
      setSelectedActivity(val);
    } else {
      setSelectedActivity(
        getDefaultActivity(ticket?.bookingConfirmationSettings?.days) || {
          name: "On day of Activity",
          value: 0,
        }
      );
    }
  };

  const handlePickupPointChange = (val) => {
    if (val) {
      setSelectedPickupPoint(val);
    }
  };
  const handleBookingConfirmationChange = (val) => {
    if (val) {
      setSelectedBookingConfirmation(val);
    } else {
      setSelectedBookingConfirmation({
        name: "Instant confirmation",
        value: "INSTANT",
      });
    }
  };

  const handleTagsChange = (val) => {
    if (val) {
      setSelectedTags(val);
    } else {
      setSelectedTags([]);
    }
  };

  const handleCuratorChange = (val) => {
    if (val) {
      setSelectedCurator(val);
    }
  };

  const convertImage = async (src) => {
    try {
      let binaryData;

      if (typeof src === "string") {
        // src is a URL
        binaryData = await urlToBinary(src);
      } else if (src instanceof File) {
        // src is a File object
        const blob = await blobToBinary(src);
        binaryData = blob;
      } else {
        throw new Error("Unsupported image src type");
      }

      // Create a File object
      const fileName = src instanceof File ? src.name : "defaultImage.jpg";
      const file = new File([binaryData], fileName, { type: "image/jpeg" }); // Update type accordingly

      return file;
    } catch (err) {
      console.error(`Error loading image from ${src}:`, error);
      throw error;
    }
  };

  const processImages = async (imageSources) => {
    try {
      const binaryImages = await Promise.all(imageSources.map(convertImage));
      return binaryImages;
    } catch (err) {
      console.error("Error processing images:", error);
      throw error;
    }
  };

  const submitForm = async (values) => {
    try {
      dispatch(setLoading());

      let updatedValues = { ...values };

      // Check if activeStep is 2 before processing images
      if (activeStep === 2) {
        // Remove deleted images from the ticket?.images array
        const updatedImages = values.images.filter((newImage) => !newImage.deleted);

        // Process the updated images as binary data
        const processedImages = await processImages(updatedImages);

        // Update the values with the processed images
        updatedValues = { ...values, images: processedImages };
      }

      updatedValues.additionalInfo = combineInfoAsStrings(
        updatedValues.physicalRestrictions,
        updatedValues.healthRestrictions,
        updatedValues.physicalDifficultyLevel
      );

      if (updatedValues?.title === ticket?.title) delete updatedValues?.title;
      if (updatedValues?.exclusions === ticket?.exclusions) delete updatedValues?.exclusions;
      if (updatedValues?.inclusions === ticket?.inclusions) delete updatedValues?.inclusions;
      if (updatedValues?.logistics === ticket?.logistics) delete updatedValues?.logistics;
      if (updatedValues?.languageGuides === ticket?.languageGuides)
        delete updatedValues?.languageGuides;
      if (updatedValues?.bookingRequirements === ticket?.bookingRequirements)
        delete updatedValues?.bookingRequirements;
      if (updatedValues?.badges === ticket?.badges[0]) delete updatedValues?.badges;
      if (updatedValues?.additionalInfo === ticket?.additionalInfo)
        delete updatedValues?.additionalInfo;

      // Dispatch the appropriate action based on activeStep
      switch (activeStep) {
        case 0:
          dispatch(addTicketStep0(updatedValues, ticket?._id));
          break;
        case 1:
          dispatch(setLoading());
          dispatch(addTicketStep1(updatedValues, ticket?._id));
          break;
        case 2:
          dispatch(addTicketStep2(updatedValues, ticket?._id));
          break;
        case 3:
          dispatch(addTicketStep3(updatedValues, ticket?._id));
          break;
        case 4:
          dispatch(addTicketStep4(updatedValues, ticket?._id));
          break;
        default:
          throw new Error("Invalid active step");
      }

      // Update state to show detail pages
      setTicketDetailPage(true);
      setContentDetailPage(true);
      setMediaDetailPage(true);
      setSchedulesAndPricingDetailPage(true);
      setBookingAndTicketsDetailPage(true);

      dispatch(setLoading());
    } catch (err) {
      console.error("Error processing images:", err);
      // Handle the error appropriately, e.g., show a message to the user
      dispatch(setLoading());
    }
  };

  const handleSubmit = (values, actions) => {
    submitForm(values, actions);
    actions.setTouched({});
    actions.setSubmitting(false);
  };

  const handleSubmitTranslate = (values) => {
    dispatch(updateTicketTranslations(values, ticket?._id));
  };

  const handleDetailData = () => {
    switch (tabValue) {
      case 1:
        return (
          <DynamicTranslate
            items={translateItems}
            handleSubmit={handleSubmitTranslate}
            id={ticket?._id}
          />
        );

      default:
        return null;
    }
  };

  const handleTitleIncChange = (val) => {
    if (val) {
      setSelectedTitleInc(val);
    }
  };

  const handleTitleExcChange = (val) => {
    if (val) {
      setSelectedTitleExc(val);
    }
  };

  const handlePinChange = (val) => {
    if (val) {
      setSelectedPin(val);
    } else setSelectedPin(null);
  };

  const handleVenueChange = (val) => {
    if (val) {
      setSelectedVenue(val);
    }
  };

  const handleLocationChange = useCallback(
    (loc) => {
      setSelectedLocation(loc || {});
    },
    [setSelectedLocation]
  );

  const handlePrimaryDestinationChange = (value) => {
    if (!value || !value.label) {
      setSelectedPrimaryDestination({});
    } else {
      setSelectedPrimaryDestination(value);
    }
  };
  const handleSecondaryDestinationChange = (value) => {
    if (value) {
      setSelectedSecondaryDestination(value);
    } else {
      setSelectedSecondaryDestination([]);
    }
  };
  const handleCurrencyChange = (value) => {
    if (!value || !value.name) {
      setSelectedCurrency(null);
    } else {
      setSelectedCurrency(value);
    }
  };

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0) {
      // dispatch(setLoading());
      dispatch(getOneTicket(ticket_id));
    }
  };

  function getStepContent(type, formData) {
    let detailPage;
    let setDetailPage;

    switch (type) {
      case "ticketInfo":
        detailPage = ticketDetailPage;
        setDetailPage = setTicketDetailPage;
        break;
      case "content":
        detailPage = contentDetailPage;
        setDetailPage = setContentDetailPage;
        break;
      case "media":
        detailPage = mediaDetailPage;
        setDetailPage = setMediaDetailPage;
        break;
      case "schedulesAndPricing":
        detailPage = schedulesAndPricingDetailPage;
        setDetailPage = setSchedulesAndPricingDetailPage;
        break;
      case "bookingAndTickets":
        detailPage = bookingAndTicketsDetailPage;
        setDetailPage = setBookingAndTicketsDetailPage;
        break;

      default:
        detailPage = contentDetailPage;
        setDetailPage = setContentDetailPage;
    }

    const handleEdit = () => {
      formData.resetForm();
      if (type === "ticketInfo") {
        setTicketDetailPage(false);
        setActiveStep(0);
        setContentDetailPage(true);
        setSchedulesAndPricingDetailPage(true);
        setMediaDetailPage(true);
        setBookingAndTicketsDetailPage(true);
      }
      if (type === "content") {
        setContentDetailPage(false);
        setActiveStep(1);
        setTicketDetailPage(true);
        setSchedulesAndPricingDetailPage(true);
        setMediaDetailPage(true);
        setBookingAndTicketsDetailPage(true);
      }
      if (type === "media") {
        setMediaDetailPage(false);
        setActiveStep(2);
        setTicketDetailPage(true);
        setContentDetailPage(true);
        setBookingAndTicketsDetailPage(true);
        setSchedulesAndPricingDetailPage(true);
      }
      if (type === "schedulesAndPricing") {
        setSchedulesAndPricingDetailPage(false);
        setActiveStep(3);
        setBookingAndTicketsDetailPage(true);
        setTicketDetailPage(true);
        setContentDetailPage(true);
        setMediaDetailPage(true);
      }
      if (type === "bookingAndTickets") {
        setBookingAndTicketsDetailPage(false);
        setActiveStep(4);
        setMediaDetailPage(true);
        setTicketDetailPage(true);
        setContentDetailPage(true);
        setSchedulesAndPricingDetailPage(true);
      }

      setImgDetailPage(true);
    };

    return (
      <TicketDetail
        type={type}
        formData={formData}
        detailPage={detailPage}
        setDetailPage={setDetailPage}
        handleEdit={handleEdit}
        selectedLocation={selectedLocation}
        handleLocationChange={handleLocationChange}
        handleTitleIncChange={handleTitleIncChange}
        handleTitleExcChange={handleTitleExcChange}
        handlePinChange={handlePinChange}
        setSelectedPin={setSelectedPin}
        setSelectedVenue={setSelectedVenue}
        setSelectedTitleInc={setSelectedTitleInc}
        setSelectedTitleExc={setSelectedTitleExc}
        selectedPin={selectedPin}
        selectedVenue={selectedVenue}
        handleVenueChange={handleVenueChange}
        selectedTitleInc={selectedTitleInc}
        selectedTitleExc={selectedTitleExc}
        handlePrimaryDestinationChange={handlePrimaryDestinationChange}
        handleCurrencyChange={handleCurrencyChange}
        handleSecondaryDestinationChange={handleSecondaryDestinationChange}
        selectedPrimaryDestination={selectedPrimaryDestination}
        selectedCurrency={selectedCurrency}
        selectedSecondaryDestination={selectedSecondaryDestination}
        setSelectedPrimaryDestination={setSelectedPrimaryDestination}
        handleTagChange={handleTagsChange}
        selectedTag={selectedTags}
        handleCuratorChange={handleCuratorChange}
        selectedCurator={selectedCurator}
        setSelectedCurator={setSelectedCurator}
        handleTimeActChange={handleTimeActChange}
        selectedTimeAct={selectedTimeAct}
        handleActivityChange={handleActivityChange}
        selectedActivity={selectedActivity}
        handleBookingConfirmationChange={handleBookingConfirmationChange}
        selectedBookingConfirmation={selectedBookingConfirmation}
        handlePickupPointChange={handlePickupPointChange}
        selectedPickupPoint={selectedPickupPoint}
        setSelectedPickupPoint={setSelectedPickupPoint}
      />
    );
  }

  const handleNavigation = (type) => {
    let url = null;
    if (type === "admin") {
      url = source === "users" ? `/admin/users/${sourceId}` : `/admin/tickets?`;
      navigate(url);
    } else if (type === "partner") {
      url = `/partner/tickets?locationId=${ticket?.location?._id}`;
      navigate(url);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3} mb={20}>
        <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%", mt: 8 }}>
          <Grid item size={{ xs: 12, lg: 8 }}>
            <Formik
              initialValues={initialState}
              enableReinitialize
              validationSchema={currentValidation}
              onSubmit={handleSubmit}
              innerRef={(f) => {
                setFormRefVal(f?.values);
                formRef.current = f;
              }}
            >
              {({ values, errors, touched, setFieldValue, resetForm }) => (
                <Form id={formId} autoComplete="off" onSubmit={(e) => e.preventDefault()}>
                  <MDBox>
                    <DynamicHeader
                      user={ticket}
                      image={image}
                      imgDetailPage={imgDetailPage}
                      detailTabs={detailTabs}
                      handleSetTabValue={handleSetTabValue}
                      tabValue={tabValue}
                      imageType="logo"
                      handleNavigation={() => handleNavigation(user?.role)}
                    >
                      {tabValue === 0 && (
                        <ViewEditActions
                          detailPage={imgDetailPage}
                          ticketPage
                          stateName="items"
                          handleEdit={() => {
                            setTicketDetailPage(true);
                            setContentDetailPage(true);
                            setSchedulesAndPricingDetailPage(true);
                            setMediaDetailPage(true);
                            setBookingAndTicketsDetailPage(true);
                          }}
                          handleClose={() => {
                            _setImage(ticket?.images?.[0]?.variants?.[0]?.url);
                            setImgDetailPage(true);
                          }}
                          handleSave={() => {
                            submitForm(values);
                            setImgDetailPage(true);
                          }}
                        />
                      )}
                    </DynamicHeader>
                    {tabValue === 0 ? (
                      <>
                        {[
                          "ticketInfo",
                          "content",
                          "media",
                          "schedulesAndPricing",
                          "bookingAndTickets",
                        ].map((item, index) => (
                          <Card id="profile" key={index} sx={{ marginTop: "24px" }}>
                            <MDBox p={2}>
                              <Grid container spacing={3} alignItems="center">
                                <Grid item size={{ xs: 12 }}>
                                  <MDBox height="100%" mt={0.5} lineHeight={1}>
                                    {getStepContent(item, {
                                      values,
                                      touched,
                                      formField,
                                      errors,
                                      setFieldValue,
                                      resetForm,
                                    })}
                                  </MDBox>
                                </Grid>
                              </Grid>
                            </MDBox>
                          </Card>
                        ))}
                      </>
                    ) : (
                      handleDetailData()
                    )}
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default EditTicket;
