import * as Yup from "yup";
import checkout from "../../new-recommendation/schemas/form";

const {
  formField: {
    tags,
    email,
    style,
    title,
    address,
    interests,
    openHours,
    minGuests,
    maxGuests,
    description,
    destinations,
    bookingEmail,
    webAddresses,
    callToAction,
    callToAction2,
    // primaryDestination,
    min: minPrice,
    max: maxPrice,
  },
} = checkout;

const validations = [
  Yup.object().shape({
    [title.name]: Yup.string()
      .matches(/([A-Za-z])/, title.invalidMsg)
      .required(title.errorMsg),
    [description.name]: Yup.string().required(description.errorMsg),
    [tags.name]: Yup.array().min(1, tags?.invalidMsg).required(tags?.errorMsg),

    [bookingEmail.name]: Yup.string()
      .nullable()
      .test(
        "bookingEmail-required",
        bookingEmail.errorMsg,
        function bookingEmailRequiredTest(value) {
          const { bookable } = this.parent;
          if (bookable) {
            return !!value;
          }
          return true;
        }
      )
      .test("bookingEmail-invalid", bookingEmail.invalidMsg, (value) => {
        if (value && !Yup.string().email().isValidSync(value)) {
          return false;
        }
        return true;
      }),
    [minGuests.name]: Yup.number()
      .typeError(minGuests.errorMsg)
      .min(0, minGuests.errorMsg)
      .nullable(),
    [maxGuests.name]: Yup.number()
      .typeError(maxGuests.errorMsg)
      .min(0, maxGuests.errorMsg)
      .nullable(),
  }),
  Yup.object().shape({
    // [primaryDestination.name]: Yup.string().required(primaryDestination.errorMsg),
    [destinations.name]: Yup.array()
      .min(1, destinations?.invalidMsg)
      .required(destinations?.errorMsg),
    [address.name]: Yup.string()
      .matches(/([A-Za-z\u0600-\u06FF])/, address.invalidMsg)
      .required(address.errorMsg),
  }),
  Yup.object().shape({
    // [email.name]: Yup.string().email(email.invalidMsg),
    [email.name]: Yup.string()
      .email(email.invalidMsg)
      .matches(/^[\w-]+(\.[\w-]+)*@[\w-]+(\.[\w-]+)+$/, email.invalidMsg),
    // [role.name]: Yup.string().required(role.errorMsg),

    [webAddresses.name]: Yup.string().matches(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
      webAddresses.invalidMsg
    ),
    [callToAction.name]: Yup.object().shape(
      {
        label: Yup.string().when("url", {
          is: (url) => url?.length > 0,
          then: () =>
            Yup.string()
              .matches(/([A-Za-z])/, callToAction.labelInvalidMsg)
              .required("Label is required!"),
        }),
        url: Yup.string().when("label", {
          is: (label) => label?.length > 0,
          then: () =>
            Yup.string()
              .matches(
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
                callToAction.urlInvalidMsg
              )
              .required("URL is required!"),
        }),
      },
      ["label", "url"]
    ),
    [callToAction2.name]: Yup.object().shape(
      {
        label: Yup.string().when("url", {
          is: (url) => url?.length > 0,
          then: () =>
            Yup.string()
              .matches(/([A-Za-z])/, callToAction2.labelInvalidMsg)
              .required("Label is required!"),
        }),
        url: Yup.string().when("label", {
          is: (label) => label?.length > 0,
          then: () =>
            Yup.string()
              .matches(
                /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)/,
                callToAction2.urlInvalidMsg
              )
              .required("URL is required!"),
        }),
      },
      ["label", "url"]
    ),
    // MIN PRICE
    [minPrice.name]: Yup.number()
      .nullable()
      .min(1, "Min price must be at least 1")
      .test("require-min-if-max", "Min Price is required", function (value) {
        const { [maxPrice.name]: maxVal } = this.parent;
        // If max has a value, min must not be empty
        if (maxVal !== null && maxVal !== undefined && maxVal !== "") {
          // returns false => error shows on `minPrice`
          return value !== null && value !== undefined && value !== "";
        }
        return true; // otherwise pass
      }),

    // MAX PRICE
    [maxPrice.name]: Yup.number()
      .nullable()
      .min(1, "Max price must be at least 1")
      .test("require-max-if-min", "Max Price is required", function (value) {
        const { [minPrice.name]: minVal } = this.parent;
        // If min has a value, max must not be empty
        if (minVal !== null && minVal !== undefined && minVal !== "") {
          // returns false => error shows on `maxPrice`
          return value !== null && value !== undefined && value !== "";
        }
        return true; // otherwise pass
      })
      .test(
        "max-not-less-than-min",
        "Max Price should be gretaer than Min Price",
        function (value) {
          const { [minPrice.name]: minVal } = this.parent;
          // If both have values, ensure max >= min
          if (
            minVal !== null &&
            minVal !== undefined &&
            minVal !== "" &&
            value !== null &&
            value !== undefined &&
            value !== ""
          ) {
            return value >= minVal;
          }
          return true;
        }
      ),
  }),
  // validations for Date and time Page
  Yup.object().shape({
    [openHours.name]: Yup.array().min(1, openHours.invalidMsg).required(),
  }),
  // validations for Trip Planner Page
  Yup.object().shape({
    [style?.name]: Yup.array().min(1, style?.invalidMsg).required(style?.errorMsg),

    [interests?.name]: Yup.array().min(1, interests?.invalidMsg).required(interests?.errorMsg),
  }),
];

export default validations;
