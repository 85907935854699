/* eslint-disable react/no-array-index-key */

// Libraries
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// Material UI components
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import { Avatar } from "@mui/material";

// Custom Components
import DetailList from "components/DetailList";
import { useSelector } from "react-redux";
import MDAvatar from "components/MDAvatar";
import { convertArrayToString } from "components/helper";

const Review = ({ formData, editPage = false, selectedDestination = {} }) => {
  const { values } = formData;

  const [userInfoData, setUserInfoData] = useState({});
  const [addInfoData, setAddInfoData] = useState({});
  const [fields, setFields] = useState([]);
  const partners = useSelector((state) => state.partners.partners);
  const selectedPartners = partners.filter((part) => part.id === values.partner);
  const selectedPartnerNames = selectedPartners.map((part) => part.name);

  const BigAvatar = styled(Avatar)`
    width: 74px;
    height: 74px;
  `;

  useEffect(() => {
    if (values) {
      setUserInfoData({
        Name: values.name,
        email: values.email,
        role: values.role,
      });
      if (values?.role === "partner") {
        setAddInfoData({
          Partner: selectedPartnerNames,
          Locations: values.locations,
        });
      } else if (values?.role === "concierge") {
        setAddInfoData({
          Location: Object.keys(values.location).length > 0 && values.location,
          destination: values?.destinations?.destinationName || selectedDestination?.label,
          InitialBalance: values.balance > 0 ? values.balance : 0,
          Commission: values.commission > 0 ? values.commission : 0,
          PromoCodes:
            values?.promoCodes?.length > 0 ? convertArrayToString(values?.promoCodes) : "",
          reference: values?.reference,
        });
      } else {
        setAddInfoData({
          intoText: values.bio,
          Description: values?.description,
          Languages: values.languages,
          interests: values.interests,
          destinations: values.destinations,
          recommendedExperiences: values.recommendedExperiences,
          top: values?.top,
          media: values.media,
        });
      }
    }
  }, [values]);
  useEffect(() => {
    if (values?.role === "partner" || values?.role === "curator" || values?.role === "concierge") {
      setFields([
        { id: 0, title: "User info", data: userInfoData },
        { id: 1, title: "Additional info", data: addInfoData },
      ]);
    } else {
      setFields([{ id: 0, title: "User info", data: userInfoData }]);
    }
  }, [userInfoData, addInfoData]);

  return (
    <Grid container spacing={3}>
      {!editPage && values?.role === "curator" ? (
        <Grid item size={{ xs: 12, sm: 6 }}>
          {values?.photo ? (
            <MDAvatar
              src={URL.createObjectURL(values?.photo)}
              alt="profile-image"
              size="xl"
              sx={{ "& img": { height: "100%" } }}
            />
          ) : (
            <BigAvatar
              alt="Change your profile photo"
              src={URL.revokeObjectURL(values?.photo)}
              imgProps={{
                style: {
                  maxHeight: "100%",
                  maxWidth: "100%",
                  objectFit: "cover",
                },
              }}
            />
          )}
        </Grid>
      ) : (
        ""
      )}
      {fields?.map((field, i) => (
        <Grid
          item
          key={field.id}
          size={{ xs: 12 }}
          sx={{ display: "flex", flexDirection: "column" }}
        >
          <DetailList data={field.data} title={field?.title} />
          {i + 1 !== fields?.length && (
            <div>
              <Divider sx={{ height: "1px" }} />
            </div>
          )}{" "}
        </Grid>
      ))}
    </Grid>
  );
};

// typechecking props for Review
Review.propTypes = {
  editPage: PropTypes.bool,
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  selectedDestination: PropTypes.instanceOf(Object),
};

export default Review;
