/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid2";
import Rating from "@mui/material/Rating";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { styled } from "@mui/material/styles";

// Custom components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DetailList from "components/DetailList";
import FormField from "components/FormField";
import DynamicRadioGroup from "components/DynamicRadioGroup";
import UploadMultiImage from "pages/content/locations/new-location/components/Sliders/UploadMultiImage";
import BadgesPicker from "components/AutoComplete/BadgesPicker";
import { useEffect, useState } from "react";
import GreenSwitch from "components/AutoComplete/GreenSwitch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SpecialDiets from "components/AutoComplete/Diet/SpecialDiets";
import MealsPicker from "components/AutoComplete/Diet/MealsPicker";

const AdditionalInfo = ({
  formData,
  editPage = false,
  detailPage = true,
  handleDietsChange = () => {},
  selectedSpecialDiets = [],
  handleMealsChange = () => {},
  selectedMeals = [],
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    email,
    phone,
    webAddresses,
    callToAction,
    callToAction2,
    price,
    traveltype,
    top,
    highlight,
    exclusivePartner,
    exclusiveLocation,
    // openHours,
    specialDiets,
    meals,
    min,
    max,
  } = formField;

  const [selectedBadge, setSelectedBadge] = useState(
    values?.badges !== null
      ? {
          label: values?.badges,
          value: values?.badges,
        }
      : null
  );

  const {
    email: emailV,
    phone: phoneV,
    webAddresses: webAddressesV,
    callToAction: callToActionV,
    callToAction2: callToAction2V,
    price: priceV,
    images: imagesV,
    traveltype: traveltypeV,
    top: topV,
    highlight: highlightV,
    exclusivePartner: exclusivePartnerV,
    exclusiveLocation: exclusiveLocationV,
    // openHours: openHoursV,
    specialDiets: specialDietsV,
    meals: mealsV,
    min: minV,
    max: maxV,
  } = values;

  const traveltypeOptions = [
    { value: "leisure", label: "Leisure" },
    { value: "business", label: "Business" },
  ];

  const SelectedDollarIcon = styled(AttachMoneyIcon)(() => ({
    color: "gray",
  }));

  const DollarIcon = styled(AttachMoneyIcon)(() => ({
    color: "lightgray",
  }));

  useEffect(() => {
    if (values?.badges?.label) {
      setSelectedBadge({
        label: values?.badges?.label,
        value: values?.badges?.value,
      });
    } else if (values?.badges !== null)
      setSelectedBadge({
        label: values?.badges,
        value: values?.badges,
      });
  }, [values?.badges]);

  return (
    <MDBox>
      <MDBox lineHeight={0}>
        {!editPage ? <MDTypography variant="h5">Additional info</MDTypography> : ""}
      </MDBox>
      {detailPage && editPage ? (
        <DetailList
          data={{
            email: values?.email,
            phone: values?.phone,
            website: values?.webAddresses,
            callToAction: values?.callToAction,
            callToAction2: values?.callToAction2,
            price: values?.price,
            currated: values?.currated,
            highlight: values?.highlight,
            special: values?.special,
            experienceX: values?.experienceX,
            exclusiveLocation: values?.exclusiveLocation,
            exclusivePartner: values?.exclusivePartner,
            top: values?.top,
            traveltype: values?.traveltype,
            badges: values?.badges?.label || values?.badges,
            specialDiets: values?.specialDiets,
            meals: values?.meals,
            minPrice: values?.min,
            maxPrice: values?.max,
            images: values?.images,
          }}
        />
      ) : (
        <MDBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={email.type}
                label={email.label}
                name={email.name}
                value={emailV}
                placeholder={email.placeholder}
                error={errors.email && touched.email}
                success={emailV?.length > 0 && !errors.email}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={phone.type}
                label={phone.label}
                name={phone.name}
                value={phoneV}
                placeholder={phone.placeholder}
                error={errors.phone && touched.phone}
                success={phoneV?.length > 0 && !errors.phone}
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <FormField
                type={webAddresses.type}
                label={webAddresses.label}
                name={webAddresses.name}
                value={webAddressesV}
                placeholder={webAddresses.placeholder}
                error={errors.webAddresses && touched.webAddresses}
                success={webAddressesV.length > 0 && !errors.webAddresses}
              />
            </Grid>

            <Grid item size={{ sm: 12 }}>
              <MDTypography
                sx={{ color: "black!important", fontWeight: 500, fontSize: "0.875rem" }}
              >
                Call to action 1{" "}
              </MDTypography>
              <Grid container spacing={3}>
                <Grid item container size={{ sm: 4 }}>
                  <FormField
                    type={callToAction.type}
                    label={callToAction.label}
                    name="callToAction.label"
                    value={callToActionV?.label}
                    onChange={(event) => setFieldValue("callToAction.label", event.target.value)}
                    error={errors?.callToAction?.label && touched?.callToAction?.label}
                    success={callToActionV?.label?.length > 0 && !errors?.callToAction?.label}
                  />
                </Grid>
                <Grid item container size={{ xs: 8 }}>
                  <FormField
                    type={callToAction.type}
                    label={callToAction.urlLabel}
                    name="callToAction.url"
                    value={callToActionV?.url}
                    onChange={(event) =>
                      setFieldValue("callToAction.url", event.target.value.trim())
                    }
                    error={errors?.callToAction?.url && touched?.callToAction?.url}
                    success={callToActionV?.url?.length > 0 && !errors?.callToAction?.url}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item size={{ sm: 12 }}>
              <MDTypography
                sx={{ color: "black!important", fontWeight: 500, fontSize: "0.875rem" }}
              >
                Call to action 2{" "}
              </MDTypography>
              <Grid container spacing={3}>
                <Grid item container size={{ sm: 4 }}>
                  <FormField
                    type={callToAction2.type}
                    label={callToAction2.label}
                    name="callToAction2.label"
                    value={callToAction2V?.label}
                    onChange={(event) => setFieldValue("callToAction2.label", event.target.value)}
                    error={errors?.callToAction2?.label && touched?.callToAction2?.label}
                    success={callToAction2V?.label?.length > 0 && !errors?.callToAction2?.label}
                  />
                </Grid>
                <Grid item container size={{ xs: 8 }}>
                  <FormField
                    type={callToAction2.type}
                    label={callToAction2.urlLabel}
                    name="callToAction2.url"
                    value={callToAction2V?.url}
                    onChange={(event) =>
                      setFieldValue("callToAction2.url", event.target.value.trim())
                    }
                    error={errors?.callToAction2?.url && touched?.callToAction2?.url}
                    success={callToAction2V?.url?.length > 0 && !errors?.callToAction2?.url}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container mt={2}>
            <Grid item size={{ xs: 12, sm: 6 }}>
              <DynamicRadioGroup
                groupLabel={traveltype.label}
                name={traveltype.name}
                value={traveltypeV}
                options={traveltypeOptions}
                setFieldValue={setFieldValue}
              />
            </Grid>
          </Grid>{" "}
          <Grid item size={{ xs: 12 }}>
            <FormGroup row>
              <FormControlLabel
                control={
                  <GreenSwitch
                    checked={topV}
                    name={top.name}
                    value={topV}
                    onChange={(event) => setFieldValue("top", event.target.checked)}
                  />
                }
                label={top.label}
              />
              <FormControlLabel
                control={
                  <GreenSwitch
                    checked={highlightV}
                    name={highlight.name}
                    value={highlightV}
                    onChange={(event) => setFieldValue("highlight", event.target.checked)}
                  />
                }
                label={highlight.label}
              />
              <FormControlLabel
                control={
                  <GreenSwitch
                    checked={exclusivePartnerV}
                    name={exclusivePartner.name}
                    value={exclusivePartnerV}
                    onChange={(event) => setFieldValue("exclusivePartner", event.target.checked)}
                  />
                }
                label={exclusivePartner.label}
              />
              <FormControlLabel
                control={
                  <GreenSwitch
                    checked={exclusiveLocationV}
                    name={exclusiveLocation.name}
                    value={exclusiveLocationV}
                    onChange={(event) => setFieldValue("exclusiveLocation", event.target.checked)}
                  />
                }
                label={exclusiveLocation.label}
              />
            </FormGroup>
          </Grid>
          <Grid item size={{ xs: 12 }}>
            <MDTypography variant="h6" color="text" fontWeight="regular">
              Badge
            </MDTypography>
            <MDBox
              style={{
                marginTop: "5px",
                backgroundColor: "white",
              }}
            >
              <BadgesPicker
                badge={selectedBadge || null}
                handleBadgeChange={(event) => {
                  if (event) {
                    setSelectedBadge(event);
                    setFieldValue("badges", event);
                  } else {
                    setSelectedBadge(null);
                    setFieldValue("badges", null);
                  }
                }}
              />
            </MDBox>
          </Grid>
          <Grid item size={{ xs: 12 }} sx={{ my: 2 }}>
            <MDTypography variant="h6" color="text" fontWeight="bold" sx={{ mb: 1 }}>
              Diets:
            </MDTypography>
            <MDBox
              style={{
                marginTop: "5px",
                backgroundColor: "white",
              }}
            >
              <SpecialDiets
                handleDietsChange={handleDietsChange}
                specialDiets={specialDiets}
                specialDietsV={specialDietsV || selectedSpecialDiets}
                setFieldValue={setFieldValue}
              />
              <MealsPicker
                handleMealsChange={handleMealsChange}
                meals={meals}
                mealsV={mealsV || selectedMeals}
                setFieldValue={setFieldValue}
              />
            </MDBox>
          </Grid>
          <Grid item size={{ xs: 12, sm: 6 }}>
            <MDTypography variant="h6" color="text" fontWeight="bold" sx={{ mb: 1 }}>
              Price:
            </MDTypography>
            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              <MDTypography
                sx={{
                  fontWeight: 400,
                  fontSize: "0.875rem",
                  marginRight: "24px",
                }}
              >
                {price.label}
              </MDTypography>
              <Rating
                name="customized-icons"
                value={priceV}
                max={5}
                onChange={(event, newValue) => {
                  setFieldValue("price", newValue);
                }}
                sx={{ fontSize: "1.2rem" }}
                precision={1}
                emptyIcon={<DollarIcon />}
                icon={<SelectedDollarIcon />}
              />
            </MDBox>
          </Grid>
          <Grid container direction="row">
            <Grid item size={{ sm: 12 }}>
              <MDTypography variant="h6" color="text" fontWeight="medium" sx={{ mt: 1 }}>
                Average Price:
              </MDTypography>
            </Grid>
            <Grid container direction="row" spacing={3} sx={{ width: "100%" }}>
              <Grid item size={{ sm: 6 }}>
                <FormField
                  type={min.type}
                  label={min.label}
                  name={min.name}
                  value={minV}
                  placeholder={min.placeholder}
                  error={errors?.min && touched?.min}
                  success={minV?.length > 0 && !errors?.minV}
                />
              </Grid>
              <Grid item size={{ sm: 6 }}>
                <FormField
                  type={max.type}
                  label={max.label}
                  name={max.name}
                  value={maxV}
                  placeholder={max.placeholder}
                  error={errors?.max && touched?.max}
                  success={maxV?.length > 0 && !errors?.maxV}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item size={{ sm: 12 }} sx={{ mt: 2 }}>
            <UploadMultiImage
              defaultImages={imagesV} // Pass the defaultImages prop if needed
              setFieldValue={setFieldValue}
              fieldName="images" // Replace "images" with your desired field name
            />
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

AdditionalInfo.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  detailPage: PropTypes.bool,
  handleDietsChange: PropTypes.func,
  selectedSpecialDiets: PropTypes.instanceOf(Array),
  handleMealsChange: PropTypes.func,
  selectedMeals: PropTypes.instanceOf(Array),
};

export default AdditionalInfo;
