import React from "react";
import PropTypes from "prop-types";

import FooterAddress from "./Address/index";

const FooterSection = ({ hasSocialLinks = true }) => (
  <div
    style={{
      fontSize: "12px",
      color: "#888888",
      margin: "8px 0",
      textAlign: "center",
    }}
  >
    {hasSocialLinks && (
      <table width="100%" cellPadding="0" cellSpacing="0" role="presentation">
        <tbody>
          <tr>
            <td
              width="50%"
              align="center"
              style={{
                fontSize: "17px",
                color: "#000000",
                fontWeight: "bold",
                textAlign: "center",
                verticalAlign: "top",
                paddingBottom: "10px",
              }}
            >
              Download the app
            </td>

            <td
              width="50%"
              align="center"
              style={{
                fontSize: "17px",
                color: "#000000",
                fontWeight: "bold",
                textAlign: "center",
                verticalAlign: "top",
                paddingBottom: "10px",
              }}
            >
              Follow Us
            </td>
          </tr>

          <tr>
            <td align="center">
              <a href="https://apps.apple.com/lb/app/lokalee/id1612033165">
                <img
                  src="https://email-assets.lokalee.app/email/emailImages/AppStore.png"
                  alt="App Store"
                  style={{ width: "95px", height: "34px", marginLeft: "10px" }}
                />
              </a>

              <a href="https://play.google.com/store/apps/details?id=app.lokalee&pcampaignid=web_share">
                <img
                  src="https://email-assets.lokalee.app/email/emailImages/googlePlay.png"
                  alt="Google Play"
                  style={{ width: "95px", height: "34px", marginLeft: "10px" }}
                />
              </a>
            </td>

            <td align="center">
              <a href="https://www.instagram.com/lokalee.app/">
                <img
                  src="https://email-assets.lokalee.app/email/emailImages/instagram.png"
                  alt="Instagram"
                  style={{ width: "34px", height: "34px", marginLeft: "10px" }}
                />
              </a>

              <a href="https://www.linkedin.com/company/linkedin.com-lokalee">
                <img
                  src="https://email-assets.lokalee.app/email/emailImages/linkedin.png"
                  alt="LinkedIn"
                  style={{ width: "34px", height: "34px", marginLeft: "10px" }}
                />
              </a>

              <a href="https://www.facebook.com/lokaleeapp">
                <img
                  src="https://email-assets.lokalee.app/email/emailImages/facebook.png"
                  alt="Facebook"
                  style={{ width: "34px", height: "34px", marginLeft: "10px" }}
                />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
    )}

    <FooterAddress />
  </div>
);

export default FooterSection;

FooterSection.propTypes = {
  hasSocialLinks: PropTypes.bool,
};
