const mealsList = [
  { label: "Dinner", value: "dinner" },
  { label: "Lunch", value: "lunch" },
  { label: "Brunch", value: "brunch" },
  { label: "Drinks", value: "drinks" },
  { label: "Late Night", value: "late_night" },
  { label: "Breakfast", value: "breakfast" },
];

export default mealsList;
