/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import axios from "axios";

import {
  SET_ITINERARY_LOADING,
  CLEAR_ERRORS,
  ITINERARY_STOPS_FAIL,
  ITINERARY_STOP_ADD_SUCCESS,
  ITINERARY_ADD_SUCCESS,
  ITINERARY_FAIL,
  UPDATE_ITINERARY,
} from "./Types";

// import factory from './actionsFactory';
const factory = require("./actionsFactory");

const URL = process.env.REACT_APP_API;

// Set Loading
export const setLoading = () => ({ type: SET_ITINERARY_LOADING });

// Clear Errors
export const clearErrors = () => ({ type: CLEAR_ERRORS });

// Get All Itineraries
export const getItineraries = (type) => {
  const isAGenerated = type === "AI";

  return factory.get(
    `${URL}/experiences?fields=title,label,curator,days&sort=createdAt&dir=asc&aiGenerated=${isAGenerated}`,
    "GET_ALL_ITINERARIES",
    "ITINERARY_FAIL"
  );
};

// Add Itinerary
export const addItinerary = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  form.append("title", data.title.trim());
  form.append("description", data.introText);
  form.append("label", data.travelStyle);
  form.append("curator", data.curator?.value);
  form.append("days", data.days);
  form.append("destination", data.destination?._id);
  data.images?.map((file) => {
    form.append("image", file);
    return file;
  });
  try {
    const res = await axios.post(`${URL}/experiences`, form, config);
    dispatch({
      type: ITINERARY_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ITINERARY_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Delete Itinerary - set active as false
export const deleteItinerary = (id) =>
  factory.patch(
    { active: false },
    `${URL}/experiences/${id}`,
    "DELETE_SINGLE_ITINERARY",
    "ITINERARY_FAIL"
  );

// get One Itinerary
export const getOneItinerary = (id) =>
  factory.get(`${URL}/experiences/${id}`, "GET_ONE_ITINERARY", "ITINERARY_FAIL");

// Add Itinerary
export const updateItinerary = (data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  if (data.title) form.append("title", data.title?.trim());
  form.append("description", data.introText);
  form.append("label", data.travelStyle);
  form.append("curator", data.curator?.value || data?.curator?._id);
  form.append("days", data.days);
  form.append("destination", data.destination?._id);
  data.images?.map((file) => {
    form.append("image", file);
    return file;
  });
  try {
    const res = await axios.patch(`${URL}/experiences/${data?._id}`, form, config);
    dispatch({
      type: UPDATE_ITINERARY,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ITINERARY_FAIL,
      payload: err?.response?.data.message,
    });
  }
};

// Add Stop
export const addStop = (id, tabValue, data) => async (dispatch) => {
  const config = {
    headers: { "Content-Type": "multipart/form-data" },
  };
  const form = new FormData();

  const ids =
    data?.type === "tickets" ? data?.tickets?.map((ticket) => ticket?.value) : data?.venues?.value;

  form.append("day", tabValue + 1);
  form.append("slot", data.slot);
  form.append("description", data.description);
  if (data.type?.length > 0) {
    form.append("type", data.type === "tickets" ? "Ticket" : "Venue");
  } else {
    form.append("type", "cta");
  }
  if (data?.type === "tickets") {
    ids?.map((itemId) => {
      if (itemId !== "") {
        form.append("ids", itemId.trim());
        return itemId;
      }
      return null;
    });
  } else if (data?.type === "venues") {
    if (ids !== "") {
      form.append("ids", ids?.trim());
    }
  } else {
    form.append("ctaLabel", data.ctaLabel);
    form.append("ctaUrl", data.ctaUrl);
  }
  if (data?.media === "video") form.append("video", data?.video);
  if (data?.media === "image") {
    data.images?.map((file) => {
      form.append("images", file);
      return file;
    });
  }

  try {
    const res = await axios.post(`${URL}/experiences/${id}/stop`, form, config);

    dispatch({
      type: ITINERARY_STOP_ADD_SUCCESS,
      payload: res.data,
    });
  } catch (err) {
    dispatch({
      type: ITINERARY_STOPS_FAIL,
      payload: err,
    });
  }

  return null;
};

// Delete Stop - set active as false
export const deleteStop = (id, stopId) =>
  factory.patch(
    { active: false, stopId },
    `${URL}/experiences/${id}/stop`,
    "DELETE_SINGLE_STOP",
    "ITINERARY_STOPS_FAIL"
  );
