import checkout from "./form";

const {
  addProductsFields: {
    productName,
    productCode,
    price,
    netPrice,
    defaultCurrencyCode,
    tag,
    subTag,
    tripPlanner,
  },
} = checkout;

const initialValues = {
  [productName.name]: "",
  [productCode.name]: null,
  [price.name]: null,
  [netPrice.name]: null,
  [defaultCurrencyCode.name]: { code: "USD", name: "USD" },
  [tag.name]: {},
  [subTag.name]: {},
  [tripPlanner.name]: false,
};

export default initialValues;
