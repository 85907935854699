import checkout from "./form";

const {
  formField: {
    title,
    subTitle,
    ctaLabel,
    ctaUrl,
    startDate,
    endDate,
    slideDuration,
    images,
    mobileImages,
    type,
  },
} = checkout;

const initialValues = {
  // user page
  [title.name]: "",
  [subTitle.name]: "",
  [ctaLabel.name]: "",
  [ctaUrl.name]: "",
  [startDate.name]: null,
  [endDate.name]: null,
  [slideDuration.name]: 7,
  [images.name]: [],
  [mobileImages.name]: [],
  [type.name]: "companion",
};

export default initialValues;
