/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";
import { useEffect, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid2";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewPartner page components
import AddressLoc from "components/AddressLoc";
import MyMapPicker from "components/MyMapPicker";
import DetailList from "components/DetailList";
import DestinationsPicker from "components/AutoComplete/DestinationsPicker";
import PrimaryDestinationPicker from "components/AutoComplete/PrimaryDestinationPicker";
import Danger from "components/Danger";
import { useFormikContext } from "formik";

const Address = ({
  formData,
  formField,
  detailPage = true,
  editPage = false,
  handlePrimaryDestinationChange = () => {},
  selectedPrimaryDestination = {},
  handleSecondaryDestinationChange = () => {},
  selectedSecondaryDestination = {},
  setSelectedPrimaryDestination = () => {},
}) => {
  const { values, errors, touched, setFieldValue } = useFormikContext();
  const { primaryDestination, destinations } = formField;
  const { destinations: destinationsV, primaryDestination: primaryDestinationV } = values;

  values.destinations?.map((destination) => destination?.label);
  const [currentLocation, setCurrentLocation] = useState({
    lat: "",
    lng: "",
  });
  const getLocation = async () => {
    try {
      await navigator.geolocation.getCurrentPosition((position) => {
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    }
  };

  useEffect(() => {
    getLocation();
  }, []);

  useEffect(() => {
    if (destinationsV?.length === 0 && selectedSecondaryDestination?.length === 0) {
      setSelectedPrimaryDestination(null);
      setFieldValue("primaryDestination", "");
    }
  }, [destinationsV, selectedSecondaryDestination]);

  return (
    <MDBox>
      {!editPage ? (
        <MDBox lineHeight={0}>
          <MDTypography variant="h5">Address</MDTypography>
        </MDBox>
      ) : (
        ""
      )}
      {detailPage && editPage ? (
        <>
          <DetailList
            data={{
              primaryDestination:
                selectedPrimaryDestination && Object.keys(selectedPrimaryDestination).length > 0
                  ? selectedPrimaryDestination?.label
                  : destinationsV.filter(
                      (destination) =>
                        destination.label === values?.primaryDestination?.destinationName
                    ),
              destinations:
                selectedSecondaryDestination &&
                Object.keys(selectedSecondaryDestination).length > 0 &&
                values?.destinations &&
                values?.destinations.length > 0
                  ? values?.destinations
                      .map((option) => option.label || option?.destinationName)
                      .join(", ")
                  : values.destinations?.map((destination) => destination?.label),
              address: values?.address,
              country: values?.country,
              city: values?.city,
              lat: values?.lat,
              lng: values?.lng,
            }}
          />
          <div style={{ marginTop: "16px" }}>
            <MyMapPicker
              lat={values.lat !== "" ? values.lat?.toString() : currentLocation.lat.toString()}
              lng={values.lng !== "" ? values.lng?.toString() : currentLocation.lng.toString()}
              setFieldValue={setFieldValue}
              disabled
            />
          </div>
        </>
      ) : (
        <MDBox mt={1.625}>
          <Grid item size={{ xs: 12 }} sx={{ mt: "16px" }}>
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <DestinationsPicker
                setFieldValue={setFieldValue}
                handleChange={handleSecondaryDestinationChange}
                destinations={destinations}
                destinationsV={destinationsV || selectedSecondaryDestination}
                setSelectedPrimaryDestination={setSelectedPrimaryDestination}
              />
            </div>
            <Danger validation={errors?.destinations} />
          </Grid>
          <Grid item size={{ xs: 12 }} sx={{ mt: "16px" }}>
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <PrimaryDestinationPicker
                setFieldValue={setFieldValue}
                handleChange={handlePrimaryDestinationChange}
                selectedDestination={
                  destinationsV.length === 0
                    ? null // If no destinations are selected, clear selectedPrimaryDestination
                    : selectedPrimaryDestination &&
                      Object.keys(selectedPrimaryDestination).length > 0 &&
                      destinationsV.some(
                        (destination) => destination.label === selectedPrimaryDestination.label
                      )
                    ? selectedPrimaryDestination // Display selectedPrimaryDestination if it's not empty and it's included in destinationsV
                    : destinationsV.some(
                        (destination) => destination.label === primaryDestinationV?.destinationName
                      )
                    ? primaryDestinationV // Display primaryDestinationV if selectedPrimaryDestination is empty or not included in destinationsV
                    : null // Clear selectedPrimaryDestination if it's not in destinationsV but primaryDestinationV is also not in destinationsV
                }
                destination={primaryDestination}
                destinationsObj={destinationsV || selectedSecondaryDestination}
              />
            </div>
          </Grid>
          <Grid item sx={{ marginBottom: "24px", marginTop: "25px" }}>
            <AddressLoc
              formData={formData}
              setFieldValue={setFieldValue}
              currentLocation={currentLocation}
              error={errors.address && touched.address && errors.address}
            />
          </Grid>
          <Grid item size={{ xs: 12 }}>
            <MyMapPicker
              lat={values.lat !== "" ? values.lat?.toString() : currentLocation.lat.toString()}
              lng={values.lng !== "" ? values.lng?.toString() : currentLocation.lng.toString()}
              setFieldValue={setFieldValue}
            />
          </Grid>
        </MDBox>
      )}
    </MDBox>
  );
};

// typechecking props for Address
Address.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  formField: PropTypes.instanceOf(Object).isRequired,
  detailPage: PropTypes.bool,
  editPage: PropTypes.bool,
  selectedPrimaryDestination: PropTypes.instanceOf(Object),
  handlePrimaryDestinationChange: PropTypes.func,
  handleSecondaryDestinationChange: PropTypes.func,
  selectedSecondaryDestination: PropTypes.instanceOf(Object),
  setSelectedPrimaryDestination: PropTypes.func,
};

export default Address;
