const form = {
  formId: "edit-user-form",
  formField: {
    // user page
    name: {
      name: "name",
      label: "Name *",
      type: "text",
      errorMsg: "Name is required!",
      invalidMsg: "Name should have at least 1 letter",
    },
    description: {
      name: "description",
      label: "Description *",
      type: "text",
      errorMsg: "Description is required!",
      invalidMsg: "Description should have at least 1 letter!",
    },
    email: {
      name: "email",
      label: "Email *",
      type: "email",
      errorMsg: "Email address is required!",
      invalidMsg: "Your email address is invalid",
    },
    phone: {
      name: "phone",
      label: "Phone",
      type: "text",
      invalidMsg: "Phone number is not valid / should start with (00) or (+)",
    },
    dob: {
      name: "dob",
      label: "Date of birth",
      type: "text",
    },

    role: {
      name: "role",
      label: "Role",
      type: "text",
      errorMsg: "Role is required.",
    },
    partner: {
      name: "partner",
      label: "Partner *",
      // type: "text",
      errorMsg: "Partner is required!",
      invalidMsg: "Partner should have at least 1 letter",
    },

    bio: {
      name: "bio",
      label: "Intro Text *",
      type: "text",
      errorMsg: "Intro text is required!",
    },
    reference: {
      name: "reference",
      label: "Reference Code",
      type: "text",
    },
    languages: {
      name: "languages",
      label: "Languages *",
      type: "text",
      invalidMsg: "Please select at least one language!",
    },
    photo: {
      name: "photo",
      label: "Photo *",
      type: "text",
      errorMsg: "photo is required!",
    },
    interests: {
      name: "interests",
      label: "Interests *",
      type: "array",
      errorMsg: "Please select at least one interest!",
    },
    // destinations: {
    //   name: "destinations",
    //   label: "Destinations *",
    //   type: "array",
    //   errorMsg: "Please select at least one destination!",
    // },

    tickets: {
      name: "tickets",
      label: "Recommended Experiences",
    },
    locDestinations: {
      name: "locDestinations",
    },
    destinations: {
      name: "destinations",
      label: "Destination *",
      errorMsg: "Destination is required!",
      // type: "text",
    },
    city: {
      name: "city",
      label: "City *",
      type: "text",
      errorMsg: "City is required!",
    },
    balance: {
      name: "balance",
      label: "Initial balance $ *",
      type: "number",
    },
    commission: {
      name: "commission",
      label: "Commission % *",
      type: "number",
      errorMsg: "Commission is required!",
    },
    location: {
      name: "location",
      label: "Location *",
      errorMsg: "Location is required!",
    },
    locations: {
      name: "locations",
      label: "Locations",
      // type: "text",
    },
    promoCodes: {
      name: "promoCodes",
      label: "Promocodes",
      // type: "text",
    },
  },
};

export default form;
