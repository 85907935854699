import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import { getUpdatedFilter } from "store/actions/userActions";
import { styled } from "@mui/material/styles";

const CustomSelect = styled(Select)({
  "&.MuiOutlinedInput-root": {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#00850D", // Change this to your desired border color
    },
  },
});

const CustomFilter = ({
  onFilterChanged,
  columnDef,
  pageName = "items", // Default parameter for pageName
  filters = {}, // Default parameter for filters
}) => {
  const [selectedVal, setSelectedVal] = useState("all");

  useEffect(() => {
    setSelectedVal(columnDef.defaultFilter || columnDef.tableData.filterValue || "all");
  }, [columnDef]);

  useEffect(() => {
    if (!filters.filters?.length) {
      setSelectedVal("all");
    }
  }, [filters]);

  const handleChange = (e) => {
    const val = e.target.value;
    setSelectedVal(val);
    onFilterChanged(columnDef.tableData.id, e.target.value);
  };

  return (
    <table>
      <thead>
        <tr>
          <th>
            <CustomSelect
              style={{ width: pageName === "items" ? 200 : 100 }}
              value={selectedVal}
              onChange={handleChange}
            >
              <MenuItem value="all">all</MenuItem>
              <MenuItem value="lokalee">Lokalee</MenuItem>
              {pageName === "items" || pageName === "invoices" ? (
                <MenuItem value="viator">Viator</MenuItem>
              ) : (
                <MenuItem value="reserve-out">Reserve out</MenuItem>
              )}
              <MenuItem value="lassad">Trip advisor</MenuItem>
            </CustomSelect>
          </th>
        </tr>
      </thead>
    </table>
  );
};

CustomFilter.propTypes = {
  onFilterChanged: PropTypes.func.isRequired,
  columnDef: PropTypes.instanceOf(Object).isRequired,
  pageName: PropTypes.string,
  filters: PropTypes.instanceOf(Object),
};

const mapStateToProps = (state) => ({
  filters: state.users.filters,
});

export default connect(mapStateToProps, {
  getUpdatedFilter,
})(CustomFilter);
